import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Picker from 'emoji-picker-react';
import { FaSmile } from 'react-icons/fa';

import Button from '../Button/button';

import './input.css';

class Input extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : '',
      displayEmojis: false
    };
  }

  emojiCallback = (event, emojiObject) => {
    let ctl = document.getElementById('input-text');
    let startPos = ctl.selectionStart;
    let endPos = ctl.selectionEnd;
    let text = this.state.value;
    text = `${text.slice(0, startPos)} ${emojiObject.emoji} ${text.slice(startPos, endPos)}`;

    this.setState({
      value: text,
      displayEmojis: false
    }, () => this.props.onChange(this.state.value));
  }

  render() {
    let { displayEmojis } = this.state;
    let { withEmojis } = this.props;
    if (this.props.multiLine)
      return (
        <>
          <div className='custom-input'>
            {withEmojis ?
              <Button
                label={<FaSmile />}
                onClick={() => this.setState({displayEmojis: !displayEmojis})}
                variant='link'
              /> : null
            }
            <Form.Control
              {...this.props}
              as="textarea"
              rows="3"
              className={`custom-input ${this.props.className} ${withEmojis ? 'emojis' : ''}`}
              onChange={e => {
                if (e.key !== "Enter") {
                  let value = e.target.value;
                  this.setState({value});
                }
                this.props.onChange(e.target.value);
              }}
              value={this.state.value}
              id='input-text'
            />
          </div>
          {displayEmojis ? <Picker onEmojiClick={this.emojiCallback}/> : null}
        </>
      )

    return (
      <Form.Control 
        {...this.props}
        className={`custom-input ${this.props.className}`}
        onChange={e => {
          if (e.key !== "Enter") {
            let value = e.target.value;
            this.setState({value});
          }
          this.props.onChange(e.target.value);
        }}
        value={this.state.value}
        accept={this.props.isAudio ? 'audio/*' : null}
        id='input-text'
      />
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  multiLine: PropTypes.bool,
  isAudio: PropTypes.bool,
  withEmojis: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  multiLine: false,
  isAudio: false,
  withEmojis: false
};

export default Input;