import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import Api from './api';
import {
  surveyGetSuccess,
  surveyGetFail,
  userIdSuccess,
  userIdFail,
  uploadSuccess,
  uploadFail,
  answerSuccess,
  answerFail,
  goBackSuccess,
  goBackFail,
  endSuccess,
  endFail,
  socialCountSuccess,
  socialCountFail
} from './actions';
import { ACTIONS } from "../ActionTypes";

const api = new Api();

function* sagasSurveyGetFetch(action) {
  try {
    const data = yield call(api.questionManager, action.payload.data);
    yield put(surveyGetSuccess(data));
  } catch (e) {
    yield put(surveyGetFail(e));
    Sentry.captureException(e);
  }
}

function* sagasUserIpFetch() {
  try {
    const data = yield call(api.getUserIp);
    yield put(userIdSuccess(data));
  } catch (e) {
    yield put(userIdFail(e));
    Sentry.captureException(e);
  }
}

function* sagasUploadFetch(action) {
  try {
    const data = yield call(api.uploadFile, action.payload.data);
    yield put(uploadSuccess(data));
  } catch (e) {
    yield put(uploadFail(e));
    Sentry.captureException(e);
  }
}

function* sagasAnswerFetch(action) {
  try {
    const data = yield call(api.questionManager, action.payload.data);
    if (data.result && data.result !== "ok")
      yield put(answerFail(data));
    else
      yield put(answerSuccess(data));
  } catch (e) {
    yield put(answerFail(e));
    Sentry.captureException(e);
  }
}

function* sagasGoBackFetch(action) {
  try {
    yield call(api.questionManager, action.payload.data);
    yield put(goBackSuccess());
  } catch (e) {
    yield put(goBackFail(e));
    Sentry.captureException(e);
  }
}

function* sagasEndFetch(action) {
  try {
    const data = yield call(api.questionManager, action.payload.data);
    yield put(endSuccess(data));
  } catch (e) {
    yield put(endFail(e));
    Sentry.captureException(e);
  }
}

function* sagasShareCounterFetch(action) {
  try {
    yield call(api.questionManager, action.payload.data);
    yield put(socialCountSuccess());
  } catch (e) {
    yield put(socialCountFail(e));
    Sentry.captureException(e);
  }
}

function* sagasLegalsFetch(action) {
  try {
    yield call(api.legalsFetch, action.payload.data);
  } catch (e) {
    Sentry.captureException(e);
  }
}

//Listen for redux actions
function* surveySaga() {
  yield all([
    takeLatest(ACTIONS.SURVEY.GET.FETCH, sagasSurveyGetFetch),
    takeLatest(ACTIONS.SURVEY.USER_IP.FETCH, sagasUserIpFetch),
    takeLatest(ACTIONS.SURVEY.UPLOAD_FILE.FETCH, sagasUploadFetch),
    takeLatest(ACTIONS.SURVEY.ANSWER.FETCH, sagasAnswerFetch),
    takeLatest(ACTIONS.SURVEY.GO_BACK.FETCH, sagasGoBackFetch),
    takeLatest(ACTIONS.SURVEY.END.FETCH, sagasEndFetch),
    takeLatest(ACTIONS.SURVEY.SOCIAL_COUNT.FETCH, sagasShareCounterFetch),
    takeLatest(ACTIONS.SURVEY.LEGALS.FETCH, sagasLegalsFetch),
  ]);
}

export default surveySaga;