import {ACTIONS} from "../ActionTypes";

const initialState = {
  survey: {
    loading: false,
    error: false,
    data: null
  },
  userIp: {
    loading: false,
    error: false,
    data: null
  },
  file: {
    loading: false,
    error: false,
    data: null
  },
  answer: {
    loading: false,
    error: false
  },
  goBack: {
    loading: false,
    error: false
  },
  end: {
    loading: false,
    error: false,
    data: null
  },
  socialCounter: {
    loading: false,
    error: false,
  }
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SURVEY.GET.FETCH:
      return {
        ...state,
        survey: {
          ...state.survey,
            loading: true,
          error: false,
          data: null
        }
      };
    case ACTIONS.SURVEY.GET.SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          loading: false,
          data: action.payload.data
        }
      };
    case ACTIONS.SURVEY.GET.FAIL:
      return {
        ...state,
        survey: {
          ...state.survey,
          error: true,
          loading: false
        }
      };

    case ACTIONS.SURVEY.USER_IP.FETCH:
      return {
        ...state,
        userIp: {
          ...state.userIp,
          loading: true,
          error: false,
          data: null
        }
      };
    case ACTIONS.SURVEY.USER_IP.SUCCESS:
      return {
        ...state,
        userIp: {
          ...state.userIp,
          loading: false,
          data: action.payload.data
        }
      };
    case ACTIONS.SURVEY.USER_IP.FAIL:
      return {
        ...state,
        userIp: {
          ...state.userIp,
          loading: false,
          error: true,
        }
      };
    
    case ACTIONS.SURVEY.UPLOAD_FILE.FETCH:
      return {
        ...state,
        file: {
          ...state.file,
          loading: true,
          error: false,
          data: null
        }
      };
    case ACTIONS.SURVEY.UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        file: {
          ...state.file,
          loading: false,
          data: action.payload.data
        }
      };
    case ACTIONS.SURVEY.UPLOAD_FILE.FAIL:
      return {
        ...state,
        file: {
          ...state.file,
          loading: false,
          error: true,
        }
      };

    case ACTIONS.SURVEY.ANSWER.FETCH:
      return {
        ...state,
        answer: {
          ...state.answer,
          loading: true,
          error: false
        }
      };
    case ACTIONS.SURVEY.ANSWER.SUCCESS:
      let { result, emocion } =  action.payload.data;
      let newSurveyData = state.survey.data;
      if (result !== "ok" || emocion)
        newSurveyData = {...newSurveyData, ...action.payload.data};

      return {
        ...state,
        survey: {
          ...state.survey,
          data: newSurveyData
        },
        answer: {
          ...state.answer,
          loading: false
        }
      };
    case ACTIONS.SURVEY.ANSWER.FAIL:
      return {
        ...state,
        answer: {
          ...state.answer,
          loading: false,
          error: true,
        }
      };

    case ACTIONS.SURVEY.GO_BACK.FETCH:
      return {
        ...state,
        goBack: {
          ...state.goBack,
          loading: true,
          error: false
        }
      };
    case ACTIONS.SURVEY.GO_BACK.SUCCESS:
      return {
        ...state,
        goBack: {
          ...state.goBack,
          loading: false
        }
      };
    case ACTIONS.SURVEY.GO_BACK.FAIL:
      return {
        ...state,
        goBack: {
          ...state.goBack,
          loading: false,
          error: true,
        }
      };
    
    case ACTIONS.SURVEY.END.FETCH:
      //setTimeout(() => {
        //console.log('survery final')
        return {
          ...state,
          end: {
            ...state.end,
            loading: true,
            error: false
          }
        };
      //}, 3000);
    case ACTIONS.SURVEY.END.SUCCESS:
      return {
        ...state,
        end: {
          ...state.end,
          loading: false,
          data: action.payload.data
        }
      };
    case ACTIONS.SURVEY.END.FAIL:
      return {
        ...state,
        end: {
          ...state.end,
          loading: false,
          error: true,
        }
      };

    case ACTIONS.SURVEY.SOCIAL_COUNT.FETCH:
      return {
        ...state,
        socialCounter: {
          ...state.socialCounter,
          loading: true,
          error: false
        }
      };
    case ACTIONS.SURVEY.SOCIAL_COUNT.SUCCESS:
      return {
        ...state,
        socialCounter: {
          ...state.socialCounter,
          loading: false
        }
      };
    case ACTIONS.SURVEY.SOCIAL_COUNT.FAIL:
      return {
        ...state,
        socialCounter: {
          ...state.socialCounter,
          loading: false,
          error: true,
        }
      };

    default:
      return state;
  }
}