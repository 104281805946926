import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Battery1 from '@material-ui/icons/Battery20';
import Battery2 from '@material-ui/icons/Battery30';
import Battery3 from '@material-ui/icons/Battery50';
import Battery4 from '@material-ui/icons/Battery90';
import Battery5 from '@material-ui/icons/BatteryFull';
import Sized from '@material-ui/icons/ThumbUpAlt'
import { isMobileOnly } from 'react-device-detect';


var labels = []
var selected = 0;
var next = [];

const StyledRating = withStyles({
  iconFilled: { color: '#ff6d75' },
  iconHover: { color: '#ff3d47' },
})(Rating);

const StyledRatingFace = withStyles({
  iconFilled: { color: '#65CE68' },
  iconHover: { color: '#51CF55' },
})(Rating);

const StyledRatingLike = withStyles({
  iconFilled: { color: '#7a60c0' },
  iconHover: { color: '#7a65ce' },
})(Rating);

const StyledRatingBattery = withStyles({
  iconFilled: { color: '#7a60c0' },
  iconHover: { color: '#7a65ce' },
})(Rating);


const styleForPaper = { width: '50', height: '50' };
const styleForBattery = { width: '50', height: '100' };

const styleForSize1 = { width: '45', height: '100' };
const styleForSize2 = { width: '52', height: '100' };
const styleForSize3 = { width: '55', height: '100' };
const styleForSize4 = { width: '62', height: '100' };
const styleForSize5 = { width: '70', height: '100' };

const customIcons = {
  1: { icon: <SentimentVeryDissatisfiedIcon style={styleForPaper} /> },
  2: { icon: <SentimentDissatisfiedIcon style={styleForPaper} /> },
  3: { icon: <SentimentSatisfiedIcon style={styleForPaper} /> },
  4: { icon: <SentimentSatisfiedAltIcon style={styleForPaper} /> },
  5: { icon: <SentimentVerySatisfiedIcon style={styleForPaper} /> }
};

const customIconsBattery = {
  1: { icon: <Battery1 style={styleForBattery} /> },
  2: { icon: <Battery2 style={styleForBattery} /> },
  3: { icon: <Battery3 style={styleForBattery} /> },
  4: { icon: <Battery4 style={styleForBattery} /> },
  5: { icon: <Battery5 style={styleForBattery} /> }
};

const customIconsSize = {
  1: { icon: <Sized style={styleForSize1} /> },
  2: { icon: <Sized style={styleForSize2} /> },
  3: { icon: <Sized style={styleForSize3} /> },
  4: { icon: <Sized style={styleForSize4} /> },
  5: { icon: <Sized style={styleForSize5} /> }
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other} >{customIcons[value].icon}</span>;
}

function IconContainerBattery(props) {
  const { value, ...other } = props;
  return <span {...other} >{customIconsBattery[value].icon}</span>;
}

function IconContainerSizes(props) {
  const { value, ...other } = props;
  return <span {...other} >{customIconsSize[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

class AnswerRating extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.defaultValue.oldAnswers ?
        Object.assign([], this.props.defaultValue.oldAnswers) :
        Object.assign([], this.props.answers),
      label_hover: '',
      button_disable: true,
      opacity: 0.5,
    };
    this.loadData()
  }

  loadData = () => {
    this.props.answers.forEach(answer => {
      labels[answer.key] = answer.alt
      next[answer.key] = answer.next
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.answers[0].next != this.props.answers[0].next)
      this.loadData()
  }

  componentWillUnmount() {
    selected = 0;
  }

  answerQuestion = () => {
    if (!this.state.button_disable)
      this.props.onClick({ value: selected, alt: labels[selected] }, next[selected])
  }

  onchangeActive = (newValue) => {
    if (newValue > -1) {
      this.setState({ label_hover: labels[newValue - 1] })
    } else {
      this.setState({ label_hover: labels[selected] })
    }
  }

  onchange = (newValue) => {
    selected = newValue - 1
    this.setState({ button_disable: false, opacity: 1 })
  }

  renderButton = () => (
    <div className='alignRight'>
      {this.props.buttonLabel !== null ?
        <img onClick={this.answerQuestion}
          style={{ opacity: this.state.opacity }}
          src={this.state.answers[0].image}
          width={50} />
        : null
      }
    </div>
  )

  render() {
    return (
      <div>
        <div className={'question-rating'}>
          {this.props.type[0] === 'face' ?
            <StyledRatingFace
              name={`rating-face-${this.props.answers[0].next}`}
              defaultValue={selected + 1}
              onChangeActive={(event, newHover) => {
                this.onchangeActive(newHover)
              }}
              onChange={(event, newHover) => {
                this.onchange(newHover)
              }}
              IconContainerComponent={IconContainer}
            /> :
            this.props.type[0] === 'heart' ?
              <StyledRating
                name={`rating-heart-${this.props.answers[0].next}`}
                getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                style={{ fontSize: "50px" }}
                defaultValue={selected + 1}
                onChangeActive={(event, newHover) => {
                  this.onchangeActive(newHover)
                }}
                onChange={(event, newHover) => {
                  this.onchange(newHover)
                }}
                icon={<FavoriteIcon fontSize="inherit" />}
              /> :
              this.props.type[0] === 'batery' ?
                <StyledRatingBattery
                  name={`rating-batery-${this.props.answers[0].next}`}
                  defaultValue={selected + 1}
                  onChangeActive={(event, newHover) => {
                    this.onchangeActive(newHover)
                  }}
                  onChange={(event, newHover) => {
                    this.onchange(newHover)
                  }}
                  IconContainerComponent={IconContainerBattery}
                /> :
                this.props.type[0] === 'like' ?
                  <StyledRatingLike
                    name={`rating-like-${this.props.answers[0].next}`}
                    defaultValue={selected + 1}
                    onChangeActive={(event, newHover) => {
                      this.onchangeActive(newHover)
                    }}
                    onChange={(event, newHover) => {
                      this.onchange(newHover)
                    }}
                    IconContainerComponent={IconContainerSizes}
                  /> :
                  <Rating
                    name={`rating-star-${this.props.answers[0].next}`}
                    style={{ fontSize: "50px" }}
                    defaultValue={selected + 1}
                    onChangeActive={(event, newHover) => {
                      this.onchangeActive(newHover)
                    }}
                    onChange={(event, newHover) => {
                      this.onchange(newHover)
                    }}
                  />
          }
          {!isMobileOnly ? <Box ml={6}>{this.state.label_hover}</Box> : null}
        </div >
        {this.renderButton()}
      </div >
    )
  }
}

AnswerRating.propTypes = {
  answers: PropTypes.array.isRequired,
  type: PropTypes.array.isRequired,
  buttonLabel: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
};

AnswerRating.defaultProps = {
  defaultValue: {},
};

export default AnswerRating;