import React from 'react';
import PropTypes from 'prop-types';
import { Card, ListGroup } from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Button from '../../../components/Button/button';

const SortableItem = SortableElement(({ item }) => (
  <ListGroup.Item 
    key={item.id}
    className={item.moved ? 'moved' : ''}
  >{item.label}</ListGroup.Item>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.id}`} index={index} item={item} />
      ))}
    </ul>
  );
});

class AnswersDnD extends React.Component {

  constructor(props) {
    super(props);
    this.state = {items: Object.assign([], this.props.items)};
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }), () => {
      let itemsAux = Object.assign([], this.state.items);
      itemsAux.forEach((item, index) => {
        let originalItem = this.props.items[index];
        if (item.id !== originalItem.id)
          item.moved = true;
        else
          item.moved = false;
      });
      this.setState({items: itemsAux});
    });
  };

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          label={this.props.buttonLabel}
          className={this.props.buttonClassName}
          onClick={() => {
            let { items } = this.state;
            let answer = [];
            let options = [];
            items.forEach((item, index) => {
              answer.push(item.label);
              options.push({
                id: index,
                label: item.label,
                value: index,
                moved: item.moved ? true : false
              });
            });
            this.props.onClick({ value: answer, alt: <AnswersDnD items={options} onClick={() => { }} buttonLabel={null} /> });
          }}
        /> : null
      }
    </>
  )

  render() {

    return (
      <>
        {this.state.items ?
          <div className='answer-dnd'>
            <Card>
              <ListGroup variant="flush">
                <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
              </ListGroup>
            </Card>
            {this.renderButton()}
          </div> : null
        }
      </>
    )
  }
}

AnswersDnD.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired
};

export default AnswersDnD;