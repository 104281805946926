import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';
import { surveyReducer } from './views/surveys/reducer';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  surveyReducer
});
// Configuration to persist reducer's information after a page's refresh
// http://bhagyashritechblogs.com/how_not_to_lose_your_redux_state_on_page_refresh_using_redux_persist
const persistConfig = {
	key: 'root',
	storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: []
}
const myPersistReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(myPersistReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export const Store = store;
export const Persistor = persistor;