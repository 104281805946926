import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'react-bootstrap';

import Button from '../../../components/Button/button';

const AnswerButtons = props => (
  <div className={props.className}>
    <ButtonToolbar>
    {console.log(props)}
      {props.answers.map(answer => {
        return (
          <Button
            key={answer.key.toString()}
            onClick={() => props.onClick({ value: answer.value, alt: answer.alt }, answer.next)}
            className={answer.image ? 'image-button answer-button' : 'answer-button'}
            label={answer.image ? <img src={answer.image} alt={answer.alt} /> : answer.label}
          />
        )
      })}
    </ButtonToolbar>
  </div>
)

AnswerButtons.propTypes = {
  answers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default AnswerButtons;