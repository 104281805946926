import React from 'react';
import PropTypes from 'prop-types';

import { IoIosClose } from 'react-icons/io';

import './CircleTimeout.css';

class CircleTimeout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stopTimeOut: false
    };
  }

  componentDidMount() {
    this.displayReloadModal()
  }

  componentWillUnmount() {
    this.setState({stopTimeOut: true});
  }

  displayReloadModal = () => {
    setTimeout(
      () => {
        if (!this.state.stopTimeOut) {
          this.props.displayReload();
          this.displayReloadModal();
        }
      }, this.props.initialTime * 60 * 1000);
  }

  render() {
    let { availableTime, initialTime } = this.props;
    return (
      <div className='circle-timeout' onClick={() => this.props.onClick()}>
        <div id="countdown">
          <div id="countdown-number"
            style={{color: `${availableTime < 2 ? 'red' : '#333'}`}}
          >
            {availableTime > 1 ? availableTime : <IoIosClose />}
          </div>
          <svg>
            <circle 
              r="10" 
              cx="20" 
              cy="20" 
              style={{
                animation: `countdown ${initialTime ? initialTime * 60 : 30}s linear infinite forwards`,
                // strokeDashoffset: `${(initialTime - availableTime) * 20}px`,
                stroke: `${availableTime < 2 ? 'red' : '#333'}`
              }}
            />
          </svg>
        </div>
      </div>
    )
  }
}

CircleTimeout.propTypes = {
  availableTime: PropTypes.number.isRequired,
  initialTime: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CircleTimeout;