import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '../../../components/Button/button';
import Input from '../../../components/Input/input';

class AnswerDateTime extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null,
      answer: null
    };

    if (this.props.defaultValue && this.props.defaultValue.value) {
      const { value } = this.props.defaultValue;
      this.state.date = moment(value).format('YYYY-MM-DD');
      this.state.time = moment(value).format('HH:mm');
    }
  }

  canAnswerQuestion = () => this.props.isRequired ? this.state.answer : true;

  answerQuestion = () => {
    if (this.canAnswerQuestion())
      this.props.onClick({ value: this.state.answer, alt: this.state.answer.format('DD-MM-YYYY HH:mm').toString() });
  }

  onInputChange = () => {
    let value = moment(this.state.date + ' ' + this.state.time);
    this.setState({answer: this.state.date && this.state.time ? value : null});
  }

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={this.answerQuestion}
          className={this.props.buttonClassName}
          label={this.props.buttonLabel}
          disabled={!this.canAnswerQuestion()}
        /> : null
      }
    </>
  )

  render() {
    return (
      <div className={`answer-text datetime ${this.props.className}`}>
        <Input
          onChange={value => this.setState({date: value}, this.onInputChange)}
          className={this.props.inputClassName}
          type='date'
          value={this.state.date}
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              this.answerQuestion();
            }
          }}
        />
        <Input
          onChange={value => this.setState({time: value}, this.onInputChange)}
          className={this.props.inputClassName}
          type='time'
          value={this.state.time}
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              this.answerQuestion();
            }
          }}
        />
        
        {this.renderButton()}
      </div>
    )
  }
}

AnswerDateTime.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.any.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  defaultValue: PropTypes.object,
  isRequired: PropTypes.bool
};

AnswerDateTime.defaultProps = {
  defaultValue: {},
  isRequired: true
};

export default AnswerDateTime;