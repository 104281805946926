import React from 'react';
import PropTypes from 'prop-types';

import AnswerButtons from './answerButtons';
import AnswerCheckboxes from './answerCheckboxes';
import AnswerRadios from './answerRadios';
import GoogleMaps from './maps';
import { AnswerFile, EndSurvey, AnswerText, JackPot, Ruleta } from '../index';
import AnswerSelect from './answerSelect';
import AnswersCarousel from './answerCarousel';
import AnswersDnD from './answerDnD';
import AnswersRating from './answerRating';
import AnswerDateTime from './answerDateTime';

import Button from '../../../components/Button/button';

const buildOptions = question => {
  let options = [];
  let i = 0;
  let indexRange = 0;
  let validAnswers = Object.assign([], question.validAnswers);
  while (i < validAnswers.length) {
    let value = validAnswers.shift();
    let image = question.images[indexRange];
    let alt = validAnswers.shift();
    let next = question.next[indexRange];
    options.push({ alt, image, value, key: indexRange, next });
    indexRange++;
  }
  return options;
}

const AnswerType = props => {
  let { question } = props;
  let options, i, indexRange, validOptions;
  let buttonLabel = props.buttonLabel !== undefined ? props.buttonLabel : question.validAnswers[1];


  switch (question.type) {
    case '0': // Answer yes or no
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '1': // Faces 
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '2': // NPS
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '3': // Text
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        isRequired={props.isRequired}
        isAudio={false}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '4': // Select
      options = [];
      question.options.map((option, index) => options.push({
        label: option,
        value: option,
        key: index,
        next: question.next[index]
      }))
      return <AnswerSelect
        answers={options}
        onClick={(value, next) => props.onAnswer(value, next)}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '5': // Checkbox
      options = [];
      question.options.forEach((option, index) => {
        // if (index === question.options.length - 1)
        //   option = `${option}*`;
        options.push({
          label: option,
          value: option,
          key: index
        })
      });
      return <AnswerCheckboxes
        onClick={value => props.onAnswer(value, question.next[0])}
        answers={options}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '6': // Radio
      options = [];
      question.options.forEach((option, index) => options.push({
        label: option,
        value: index,
        key: index,
        next: question.next[index]
      }))
      return <AnswerRadios
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        radiosName={question.questionId}
        buttonLabel={buttonLabel}
        className='inline'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '7': // Mensaje/audio
      // CHEQUEAR: PROBAR, ¿puede ser texto y audio?
      /* 
      Pueden escribir o pueden hablarle al celular y que este suba el archivo a s3,
      le hago un post a una URL que me va a dar Marce y ese endpoint me devuelve algo que
      tengo que guardar el nombre del archivo en s3 e inyectarlo en el answer (el user no debe
      ver ese nombre)
      */
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        isAudio={true}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    /*
    case '8': // Audio
      // No acepta texto, sólo audio
      return <AnswerText
        isAudioonClick={value => props.onAnswer(value)}
        isAudio={true}
        buttonLabel={question.validAnswers[1]}
      />
    */
    case '9': // Info
      return <Button
        onClick={() => props.onAnswer({
          value: question.validAnswers[0], alt: null
        }, question.next[0])}
        label={question.validAnswers[1]}
        className='answer-btn'
        key={question.questionId}
        questionId={question.questionId}
      />
    case '10': // Email
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        type='email'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '11': // Teléfono
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        type='number'
        min='0'
        step='1'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '12': // Fecha
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        type='date'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '13': // Hora
      return <AnswerDateTime
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '14': // Juego Slot
      // Se va a subir el juego a un server aparte para inyectar con iframe
      return <JackPot
        labels={question.options}
        onAnswer={(value, next) => props.onAnswer(value, next)}
        key={question.questionId}
        questionId={question.questionId}
        nextQuestions={question.next}
      />
    case '15': // Caritas7
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '16': // Radio Imagenes
      options = [];
      question.options.forEach((option, index) => options.push({
        image: `${process.env.REACT_APP_S3_URL}${option.split('?')[0]}`,
        value: option.split('?')[1],
        key: index,
        next: question.next[index]
      }));

      return <AnswerRadios
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        radiosName={question.questionId.toString()}
        buttonLabel={buttonLabel}
        className='inline'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '17': // TextArea
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        multiLine={true}
        defaultValue={question.answer ? question.answer : {}}
        withEmojis={props.withEmojis}
        isRequired={props.isRequired}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '18': // Radio Imagenes Link
      // Se debe mostrar un radio por fila con un botón al lado que abre un modal
      options = [];
      i = 0;
      indexRange = 0;
      validOptions = Object.assign([], question.options);
      while (i < validOptions.length) {
        let option = validOptions.shift();
        let image = `${process.env.REACT_APP_S3_URL}${option.split('?')[0]}`;
        let moreInfo = option.split('?')[2];
        let value = option.split('?')[1];
        let next = question.next[indexRange];
        options.push({ alt: value, image, value, key: indexRange, next, moreInfo });
        indexRange++;
      }
      return <AnswerRadios
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        radiosName={question.questionId.toString()}
        buttonLabel={buttonLabel}
        className='inline'
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '19': // Google Map
      // Es un mapa de google maps que el usuario ingresa su dirección y el mapa 
      // se para sobre la ubicación con un marker, al darle en siguiente se muestra
      // la dirección del negocio mientras que el back recibe la dirección de lat, lng y
      // todo el texto de la dirección (ojo con dirección y nombre de usuario)
      return <GoogleMaps
        buttonLabel={buttonLabel}
        onClick={questionAnswer => props.onAnswer(questionAnswer, question.next[0])}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '20': // Caritas 4
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '21': // Caritas 3
      options = buildOptions(question);
      return <AnswerButtons
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        key={question.questionId}
        questionId={question.questionId}
      />
    /*
    case '22': // Multiple Select
      // Selectores anidados, por ej el usuario ingresa país y luego se le pregunta provincia
      return <RespondedorMultipleSelect
        respuestaNueva={respuestaNueva}
        pregunta={pregunta}
      />
    */
    case '23': // Audio Nuevo
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        isAudio={true}
        buttonLabel={buttonLabel}
        defaultValue={question.answer ? question.answer : {}}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '24': // Imagen
      // Se puede sacar una imagen o subir una, tamaño máximo 512kb (fijarme de validar el front)
      return <AnswerFile
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        key={question.questionId}
        questionId={question.questionId}
        errorMessage={question.validAnswers}
      />
    /*
    case '25': // Filmar un video
      return <RespondedorVideo
        respuestaNueva={respuestaNueva}
        pregunta={pregunta}
      />
    */
    case '26': // Carousel
      let carouselOptions = [];
      question.options.forEach((option, index) => {
        carouselOptions.push({
          id: index,
          image: `${process.env.REACT_APP_S3_URL}${option.split('?')[0]}`,
          name: option.split('?')[1],
          text: option.split('?')[1],
          next: question.next[index]
        });
      });

      return <AnswersCarousel
        key={question.questionId}
        questionId={question.questionId}
        items={carouselOptions}
        onClick={(value, next) => props.onAnswer(value, next)}
        buttonLabel={buttonLabel}
      />
    case '27':
      options = [];
      question.options.forEach((option, index) => {
        options.push({
          id: index,
          value: index,
          label: option,
        });
      });

      return <AnswersDnD
        items={options}
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
      />
    case '28': // Emotion
      return <AnswerFile
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        cameraOnly={true}
        errorMessage={question.validAnswers}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '29': // Stars
      options = buildOptions(question);
      return <AnswersRating
        onClick={(value, next) => props.onAnswer(value, next)}
        answers={options}
        buttonLabel={buttonLabel}
        type={question.options}
      />
    case '30': // Name
      return <AnswerText
        onClick={value => props.onAnswer(value, question.next[0])}
        buttonLabel={buttonLabel}
        multiLine={false}
        defaultValue={question.answer ? question.answer : {}}
        withEmojis={props.withEmojis}
        key={question.questionId}
        questionId={question.questionId}
      />
    case '31': // Juego Slot
      // Se va a subir el juego a un server aparte para inyectar con iframe
      return <Ruleta
        labels={question.options}
        onAnswer={(value, next) => props.onAnswer(value, next)}
        key={question.questionId}
        questionId={question.questionId}
        nextQuestions={question.next}
      />
    case '99':
      return <EndSurvey setForm={props.setForm} />

    default:
      return <div>no hay tipo pregunta</div>;
  }
}

AnswerType.propTypes = {
  question: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired
};

export default AnswerType;