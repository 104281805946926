import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {
  surveyGetFetch,
  userIdFetch,
  uploadFetch,
  answerFetch,
  goBackFetch,
  endFetch,
  socialCountFetch,
  legalsFetch
} from './actions';
import SurveyComponent from './surveys';
import AnswerFileComponent from './components/answerFile';
import EndSurveyComponent from './components/endSurvey';
import AnswerTextComponent from './components/answerText';
import JackPotComponent from './components/jackPot';
import RuletaComponent from './components/ruleta';

const mapStateToProps = state => {
  return {
    survey: state.surveyReducer.survey,
    userIp: state.surveyReducer.userIp,
    answer: state.surveyReducer.answer,
    goBack: state.surveyReducer.goBack
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  _surveyGetFetch: surveyGetFetch,
  _userIdFetch: userIdFetch,
  _answerFetch: answerFetch,
  _goBackFetch: goBackFetch,
  _legalsFetch: legalsFetch
}, dispatch);

export const Survey = connect(mapStateToProps, mapDispatchToProps)(SurveyComponent);

// File's uploader
const fileMapStateToProps = state => {
  return {
    file: state.surveyReducer.file,
    answer: state.surveyReducer.answer,
  };
};

const fileMapDispatchToProps = dispatch => bindActionCreators({
  _uploadFetch: uploadFetch
}, dispatch);

export const AnswerFile = connect(fileMapStateToProps, fileMapDispatchToProps)(AnswerFileComponent);
export const AnswerText = connect(fileMapStateToProps, fileMapDispatchToProps)(AnswerTextComponent);

// End survey
const endMapStateToProps = state => {
  return {
    end: state.surveyReducer.end,
    survey: state.surveyReducer.survey,
    socialCounter: state.surveyReducer.socialCounter
  };
};

const endMapDispatchToProps = dispatch => bindActionCreators({
  _endFetch: endFetch,
  _socialCountFetch: socialCountFetch
}, dispatch);

export const EndSurvey = connect(endMapStateToProps, endMapDispatchToProps)(EndSurveyComponent);

// Jackpot
const jackPotMapStateToProps = state => {
  return {
    survey: state.surveyReducer.survey
  };
};
// Ruleta
const _ruletaMapStateToProps = state => {
  return {
    survey: state.surveyReducer.survey
  };
};

const jackPotMapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const ruletaMapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export const JackPot = connect(jackPotMapStateToProps, jackPotMapDispatchToProps)(JackPotComponent);
export const Ruleta = connect(_ruletaMapStateToProps, ruletaMapDispatchToProps)(RuletaComponent);