import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Radio from '../../../components/Radio/radio';
import Button from '../../../components/Button/button';
import Input from '../../../components/Input/input';

class AnswerRadios extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.defaultValue.oldAnswers ?
        Object.assign([], this.props.defaultValue.oldAnswers) :
        Object.assign([], this.props.answers),
      selectedAnswer: null,
      displayText: false,
      keyWithText: null,
      othersText: '',
      lastAnswer: null,
      next: null
    };

    this.state.answers.forEach(answer => {
      if (answer.label && answer.label.slice(-1) === '*')
        this.state.keyWithText = answer.key;
      if (answer.selected)
        this.state.selectLabel = answer.label;
    });
  }

  handleClose = () => this.setState({ moreInfo: null });

  getMoreInfo = option => this.setState({ moreInfo: option });

  onSelect = (answer, index) => {
    let { answers } = this.state;
    this.state.answers.forEach(answer => answer.selected = false);
    answer.selected = true;
    answers[index] = answer;
    this.setState({
      answers,
      selectedAnswer: answer,
      displayText: answer.key === this.state.keyWithText,
      lastAnswer: answer.key,
      next: answer.next
    });
  }

  buttonDisabled = () => {
    return !this.state.selectedAnswer || (
      this.state.selectedAnswer && this.state.displayText && !this.state.othersText);
  }

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={() => {
            let { selectedAnswer, next, displayText, othersText, answers } = this.state;
            let alt = selectedAnswer.label;
            let value = selectedAnswer.key;
            if (displayText) {
              value = `${value}|${othersText}`;
              alt = `${alt}: ${othersText}`;
            }
            this.props.onClick({ value, alt, oldAnswers: answers }, next);
          }}
          className={this.props.buttonClassName}
          label={this.props.buttonLabel}
          disabled={this.buttonDisabled()}
        /> : null
      }
    </>
  )

  renderMoreInfo = answer => (
    <>
      {answer.moreInfo ?
          <Button
            variant='link'
            className='more-info'
            onClick={() => this.getMoreInfo(answer)}
            label='+ info'
          /> : null
        }
    </>
  )

  render() {
    return (
      <div className={`answer-radio ${this.props.className}`}>

        {this.state.moreInfo ?
          <Modal show={true} onHide={() => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.moreInfo.alt}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                src={this.state.moreInfo.moreInfo}
                style={{ display: 'inline-block', position: 'relative', width: '100%', height: '550px' }}
                title={this.state.moreInfo.alt}
              />
            </Modal.Body>
          </Modal> : null
        }

        {this.state.answers.map((answer, index) => (
          <div key={answer.key.toString()}>
            <Radio
              onChange={() => this.onSelect(answer, index)}
              className={answer.image ? 'image-radio answer-radio' : 'answer-radio'}
              label={answer.image ?
                <img
                  src={answer.image}
                  alt={answer.value}
                  className={!answer.selected ? 'checked' : ''}
                  onClick={() => this.onSelect(answer, index)}
                /> : answer.label
              }
              id={`id-check-${index}-${answer.next.toString()}-${Math.random()*100}`}
              value={answer.value}
              name={this.props.radiosName}
              defaultChecked={answer.selected}
            />
            
            {this.renderMoreInfo(answer)}
          </div>
        ))}

        {this.state.displayText ?
          <Input onChange={label => this.setState({ othersText: label })} /> : null
        }
        
        {this.renderButton()}
      </div>
    )
  }
}

AnswerRadios.propTypes = {
  answers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  radiosName: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  defaultValue: PropTypes.object
};

AnswerRadios.defaultProps = {
  defaultValue: {},
};

export default AnswerRadios;