import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../components/Checkbox/checkbox';
import Button from '../../../components/Button/button';
import Input from '../../../components/Input/input';

class AnswerCheckboxes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.defaultValue.oldAnswers ?
        Object.assign([], this.props.defaultValue.oldAnswers) :
        Object.assign([], this.props.answers),
      displayText: false,
      keyWithText: null,
      othersText: ''
    };

    this.state.answers.forEach(answer => {
      if (answer.label && answer.label.slice(-1) === '*')
        this.state.keyWithText = answer.key;
    });
  }

  onCheckboxChange = (answer, index) => {
    let { answers, displayText, othersText } = this.state;
    answer.checked = !!!answer.checked;
    answers[index] = answer;
    if (answer.key === this.state.keyWithText) {
      displayText = answer.checked;
      if (!answer.checked)
        othersText = '';
    }
    this.setState({ answers, displayText, othersText });
  };

  buttonDisabled = () => {
    let nobodyCheck = this.state.answers.filter(answer => answer.checked).length === 0;
    return nobodyCheck || (
      !nobodyCheck && this.state.displayText && !this.state.othersText);
  }

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={() => {
            let { answers } = this.state;
            let value = '', alt = '';
            let selectedAnswers = answers.filter(item => item.checked);
            selectedAnswers.forEach((answer, index) => {
              value = `${value}${answer.key}`;
              alt = `${alt}${answer.label}`;
              if (answer.label?.slice(-1) === '*') {
                value = `${value}|${this.state.othersText}`;
                alt = `${alt}: ${this.state.othersText}`;
              }
              if (index !== selectedAnswers.length - 1) {
                value = `${value}|`;
                alt = `${alt}, `;
              }
            });
            this.props.onClick({ value, alt, oldAnswers: answers });
          }}
          className={this.props.buttonClassName}
          label={this.props.buttonLabel}
          disabled={this.buttonDisabled()}
        /> : null 
      }
    </>
  )

  render() {
    let { answers, displayText } = this.state;
    return (
      <div className={`answer-checkbox ${this.props.className}`}>
        {answers.map((answer, index) => {
          return (
            <Checkbox
              key={answer.key.toString()}
              onChange={() => this.onCheckboxChange(answer, index)}
              className={answer.image ? 'image-checkbox answer-checkbox' : 'answer-checkbox'}
              label={answer.image ? <img src={answer.image} alt={answer.value} /> : answer.label}
              checked={answer.checked}
            />
          )
        })}
        {displayText ?
          <Input
            onChange={label => this.setState({ othersText: label })}
            value={this.state.othersText}
          /> : null
        }
        {this.renderButton()}
      </div>
    )
  }
}

AnswerCheckboxes.propTypes = {
  answers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.any.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.object
};

AnswerCheckboxes.defaultProps = {
  defaultValue: {},
};

export default AnswerCheckboxes;