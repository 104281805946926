import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import './checkbox.css';

const Checkbox = props => (
  <Form.Check
    type='checkbox'
    onChange={() => props.onChange()}
    label={props.label}
    checked={props.checked}
  />
)

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool
};

export default Checkbox;