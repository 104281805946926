/* var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios"); */

import axios from "axios";

class Api {
  questionManager = data => new Promise((res, rej) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}poller_service.php`, data)
      .then(response => {
        // console.log(1, response.data);
        res(response.data);
      })
      .catch(e => rej(e.response))
  }).then(res => res);

  getUserIp = () => new Promise((res, rej) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}ip_service.php`)
      .then(response => res(response.data[0]))
      .catch(e => rej(e.response))
  }).then(res => res);

  uploadFile = formData => new Promise((res, rej) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}poller_s3.php?f=uploadFile`, formData)
      .then(response => res(response.data))
      .catch(e => rej(e.response))
  }).then(res => res);

  legalsFetch = formData => new Promise((res, rej) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}poller_service.php`, formData)
      .then(response => res(response.data))
      .catch(e => rej(e.response))
  }).then(res => res);
}

export default Api;