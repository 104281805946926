import ReactGA from 'react-ga';


export default class Analytics {
  static initializeReactGA = () => ReactGA.initialize(
    process.env.REACT_APP_GA_KEY,
    { debug: process.env.REACT_APP_GA_DEBUG === 'true' ? true : false }
  );

  static pageview = page => ReactGA.pageview(page);

  static event = (category, action, label = '', value = null, nonInteraction = false) => {
    try {
      if (value === null)
        ReactGA.event({
          category,
          action,
          label,
          nonInteraction
        });
      else
        ReactGA.event({
          category,
          action,
          label,
          value: parseInt(value),
          nonInteraction
        });
    } catch (e) { }
  };
}
