import React from 'react';
import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Modal,
  OverlayTrigger,
  Popover, Alert
} from 'react-bootstrap';
import {
  deviceDetect,
  browserName,
  BrowserView,
  MobileView,
  isMobile,
  isMobileOnly,
  isIOS
} from 'react-device-detect';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import findIndex from 'lodash.findindex';

import { GoBriefcase } from 'react-icons/go';

import Analytics from '../../services/Analytics';

import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import CircleTimeout from '../../components/CircleTimeout/CircleTimeout';

import AnswerType from './components/answerTypes';
import Legals from './components/legals';

import './surveys.css';
import blop from '../../assets/sounds/blop.mp3';
import phoneImage from '../../assets/img/phone.png';

/*
elink eran anonimos y links eran encuestas mandadas, no?
elink + email /e/
elink + sms /r/
link + email /le/
link + sms /l/
ej elink con email= https://witcx.com/e/demoagencia/alguno@alguno.com/

hashLink me dice que encuesta es, los elink no piden nombre y apellido, los link si
surveyType me dice de donde viene (e, r, le, l)
identificador para reconocer que usuario es
*/

class Survey extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: null,
      questions: {},
      previousQuestion: null,
      answers: [],
      requiredField: false,
      requiredFieldType: null,
      requiredFieldLabel: null,
      requiredFieldValue: '',
      requiredFieldIsValid: false,
      leftImage: '',
      initialText: '',
      imagenInicialPC: null,
      imagenInicialMovil: null,
      giftText: '',
      giftImage: null,
      initialLogo: '',
      initialImage: '',
      companyName: '',
      questionSound: new Audio(blop),
      phoneImage: phoneImage,
      renderType: 'Responsive',
      chatName: '',
      lastActivity: new Date(),
      showPopover: true,
      showLegals: null,
      legalsRequired: false,
      canPlay: true,
      legalsSubmited: false,
      displayCustomError: false,
    };

    Analytics.pageview(window.location.pathname + window.location.search);
  }

  handleScroll = () => {
    const header = document.getElementById('chatHeader');
    const container = document.getElementById('container');
    const sticky = header?.offsetTop;

    if (header) {
      if (sticky !== undefined && window.pageYOffset > sticky && this.state.questions[this.state.currentQuestion].type != 99)
        header.classList.add('sticky');
      else {
        if (this.state.questions[this.state.currentQuestion].type == 99) {
          container.classList.add('height-100')
        }
        header.classList.remove('sticky');
      }
    }
  };


  componentDidMount() {
    this.props._userIdFetch();
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentDidUpdate(prevProps, prevState) {
    let prevSurvey = prevProps.survey;
    let prevUserIp = prevProps.userIp;
    let prevAnswer = prevProps.answer;
    let prevGoBack = prevProps.goBack;
    let { survey, userIp, answer, goBack } = this.props;

    //console.log(survey)

    if (this.props !== prevProps)
      this.setState({ lastActivity: new Date() });

    // Find IP
    if (prevUserIp.loading && !userIp.loading) {
      this.findIp();
    }

    // First load or after answer a question
    if ((prevSurvey.loading && !survey.loading) || (prevAnswer.loading && !answer.loading)) {
      if (prevAnswer.loading && !answer.loading && answer.error) {
        this._goBack();
        this.setState(
          { displayCustomError: true },
          () => setTimeout(
            () => this.setState({ displayCustomError: false }), 5000
          )
        );
        return;
      } else if (prevAnswer.loading && !answer.loading && !answer.error) {
        // This is a hack to display the emotion for question type 28
        if (survey.data.emocion) {
          let { answers } = this.state;
          answers.forEach((answer, index) => {
            if (answer.type === '28' && !answer.answer.alt)
              answers[index].answer.alt = survey.data.emocion;
          })
          this.setState({ answers });
          return;
        }
      }

      if (!survey.error && !answer.error) {
        /* 
          Necesito validar por si es la primera vez que traigo una encuesta y también
          porque si es única por mail entonces ya tengo respuestas previas que las carga
          luego de identificar el mail
        */
        if (!Object.keys(this.state.questions).length || prevState.requiredField) {
          this.setUp();
        }
      }

      // Answer a required field
      if (this.state.requiredField) {
        if (prevAnswer.loading && !answer.loading && !answer.error) {
          this.setState({ requiredField: false });
        }
      }
    }

    if (prevGoBack.loading && !goBack.loading) {
      if (!goBack.error) {
        this._goBack();
      }
    }

    if (!this.state.checkedlastActivity)
      this.setState({
        lastActivity: new Date(),
        checkedlastActivity: true
      }, () => this.checkLastActivity());
  }

  findIp = () => {
    let { userIp } = this.props;
    if (!userIp.error) {
      let clientInfo = deviceDetect();
      let ipInfo = {
        IPRespuesta: userIp.data.IPRespuesta,
        HostCliente: userIp.data.HostCliente,
        BrowserCliente: userIp.data.BrowserCliente,
        TipoCliente: clientInfo.isMobile ? 'mobile' : clientInfo.isTablet ? 'tablet' : 'desktop', // mobile/tablet/desktop
        IpSO: clientInfo.os,
        IpMarca: clientInfo.model,
        IpModelo: clientInfo.osVersion,
        IpBrowser: browserName,
      }
      let form = new FormData();
      Object.keys(ipInfo).forEach(key => form.set(key, ipInfo[key]));
      this.getSurvey(form);
      return;
    }
  }

  setUp = () => {
    let { survey } = this.props;

    Analytics.event(
      'display',
      'display-survey-title',
      survey.data.TituloInicial
    );
    Analytics.event(
      'display',
      'display-survey-id',
      survey.data.IdEncuesta?.toString(),
      parseInt(survey.data.IdEncuesta)
    );

    let questions = {};
    let previousQuestion, currentQuestion, answers = [];
    if (survey.data.Preguntas) {
      let surveyQuestions = survey.data.Preguntas;
      if (surveyQuestions.length === undefined)
        surveyQuestions = [surveyQuestions];

      previousQuestion = surveyQuestions[0].Orden;
      currentQuestion = surveyQuestions[0].Orden;
      surveyQuestions.forEach(question => {
        let formatedQuestion = question.Pregunta.split('|');
        let questionLabel = formatedQuestion.shift();
        let options = formatedQuestion;
        let validAnswers = question.PosiblesRespuestas.split('|');
        let nextQuestions = question.Siguiente ? question.Siguiente.split('|') : [`${parseInt(question.Orden) + 1}`]
        let answer = question.Respuesta;

        // Make answer's label
        if (answer) {
          answer = answer.split('|')
          let mainAnswer = answer.shift();
          let extraAnswer = answer;

          if (options) {
            if (!isNaN(mainAnswer) && parseInt(mainAnswer) <= options.length - 1)
              mainAnswer = options[parseInt(mainAnswer)];
            else if (options.includes(mainAnswer)) {
              let answerLabelIndex = findIndex(options, item => item === mainAnswer);
              if (answerLabelIndex !== -1)
                mainAnswer = options[answerLabelIndex + 1];
            }
          }

          if (validAnswers.includes(mainAnswer)) {
            let answerLabelIndex = findIndex(validAnswers, item => item === mainAnswer);
            if (answerLabelIndex !== -1)
              mainAnswer = validAnswers[answerLabelIndex + 1];
          }

          answer = extraAnswer.length ? `${mainAnswer}|: ${extraAnswer.join('|')}` : mainAnswer;
          answer = answer ? answer.replace('*|', '') : null;
        }

        questions[question.Orden] = {
          questionId: question.IdPregunta,
          end: question.Finalizar,
          questionLabel: questionLabel,
          options: options,
          next: nextQuestions,
          type: question.IdTipoPregunta,
          order: question.Orden,
          answer: { value: question.Respuesta, alt: answer },
          images: question.GraficosPreguntas.split('|'),
          validAnswers: validAnswers,
          help: question.TextoAyuda
        }

        // Check last answer question
        if (question.Respuesta && question.Orden !== previousQuestion) {
          questions[question.Orden].previous = previousQuestion;
          previousQuestion = question.Orden;
        }

        // Check current question
        if (question.Respuesta) {
          let mainAnswer = question.Respuesta.split('|').shift();
          if (!isNaN(mainAnswer) && validAnswers.includes(mainAnswer))
            currentQuestion = nextQuestions[parseInt(mainAnswer)];
          else
            currentQuestion = nextQuestions[0];
        }

        // Check if questions was answer
        if (question.Respuesta) {
          answers.push({
            ...questions[question.Orden],
            answers: { answer: question.Respuesta, next: nextQuestions }
          });
        }
      });
    } else {
      currentQuestion = 0;
      questions[0] = { type: '99' };
    }

    let requiredFieldLabel, requiredFieldSuccess, requiredFieldError;
    if (survey.data.PideDatoContactoLabel) {
      requiredFieldLabel = survey.data.PideDatoContactoLabel.split('|')[0];
      requiredFieldSuccess = survey.data.PideDatoContactoLabel.split('|')[1];
      requiredFieldError = survey.data.PideDatoContactoLabel.split('|')[2];
    }

    this.setState({
      questions,
      currentQuestion,
      answers,
      previousQuestion,
      questionSound: survey.data.DatoGeneralSonido ? new Audio(survey.data.DatoGeneralSonido) : this.state.questionSound,
      phoneImage: survey.data.DatoGeneralCelular ? survey.data.DatoGeneralCelular : this.state.phoneImage,

      leftImage: survey.data.ImagenInicial,
      chatLogo: survey.data.BarritaLogo,
      chatImage: survey.data.ImagenChat,
      chatName: survey.data.BarritaNombre,
      initialLogo: survey.data.initialLogo,
      initialImage: survey.data.ImagenInicial,
      initialText: survey.data.TextoInicial,
      imagenInicialMovil: survey.data.ImagenInicialMovil,
      imagenInicialPC: survey.data.ImagenInicialPC,
      initialTitle: survey.data.TituloInicial,
      giftText: survey.data.TextoRegalo,
      giftImage: survey.data.ImagenRegalo,
      companyName: survey.data.BarritaNombre,
      requiredField: survey.data.PideDatoContactoTipo ? true : false,
      requiredFieldType: survey.data.PideDatoContactoTipo,
      requiredFieldLabel,
      requiredFieldSuccess,
      requiredFieldError,
      renderType: survey.data.TipoPresenta,
      showLegals: ['AceptarLegales', 'CompletoLegales'].includes(survey.data.LegalesTipo) && this.state.showLegals === null ? true : false,
      legalsRequired: ['AceptarLegales', 'CompletoLegales'].includes(survey.data.LegalesTipo) && this.state.showLegals === null ? true : false,
    });
    { document.body.style.backgroundImage = (!isMobileOnly) ? `url(${survey.data.ImagenFondo})` : null };
    document.body.classList.add(survey.data.TipoPresenta);
    document.body.classList.add('full-background-img');
    document.body.classList.add(isIOS ? 'isIOS' : null);
  }

  scrollToLastQuestion = (time = 1000) => {
    let { survey } = this.props;
    let scrollableContent = document.getElementById("scrollable-content");
    if (scrollableContent && scrollableContent.lastChild) {
      if (survey.data.TipoPresenta === 'Kiosco') {
        setTimeout(() => scrollableContent.lastChild.scrollIntoView(false), time);
      } else {
        setTimeout(() => scrollableContent.lastChild.scrollIntoView(true), time);
      }
    }

  }

  setFormQueryStringValues = (querystring, form, formKey = null) => {
    if (window.location.search.indexOf(querystring) > -1) {
      let value = window.location.search.split(`${querystring}=`)[1].split('&')[0];
      form.set(formKey ? formKey : `${querystring}`, value);
    }
    return form;
  }

  getSurvey = form => {
    let { survey } = this.props;
    let surveyType, hashLink, branch;
    if (!survey.data) {
      if (window.location.pathname !== '/') {
        let pathname = window.location.pathname.split('/');
        if (pathname.length > 1) {
          surveyType = pathname.length >= 2 ? pathname[1] : 'd';
          form.set('tipoEncuesta', surveyType);

          hashLink = pathname.length >= 2 ? pathname[2] : pathname[1];
          form.set('hashLink', hashLink);

          branch = pathname.length === 4 ? pathname[3] : '';
          form.set('branch', branch);
        }
      } else if (window.location.search.indexOf('link=') > -1) {
        hashLink = window.location.search.split('link=')[1].split('&')[0];
        form.set('hashLink', hashLink);
      }
      if (window.location.search.indexOf('&s=') > -1) {
        surveyType = window.location.search.split('&s=')[1].split('&')[0];
        surveyType = surveyType === 'email' ? 'e' : 'r';
        if (window.location.search.indexOf('elink=') === -1)
          surveyType = surveyType === 'r' ? 'l' : 'l' + surveyType;
        form.set('tipoEncuesta', surveyType);
      } else if (!surveyType) {
        form.set('tipoEncuesta', 'd');
      }

      form = this.setFormQueryStringValues('sucursal', form);
      //form = this.setFormQueryStringValues('email', form);
      form = this.setFormQueryStringValues('sex', form);
      form = this.setFormQueryStringValues('telefono', form);
      form = this.setFormQueryStringValues('nombre', form);
      form = this.setFormQueryStringValues('IdUsuarioRespuesta', form);
      form = this.setFormQueryStringValues('NRespuesta', form);
      form = this.setFormQueryStringValues('ORespuesta', form);

      for (let i = 1; i <= 10; i++) {
        if (window.location.search.indexOf(`DatoExt${i}=`) > -1) {
          form = this.setFormQueryStringValues(`DatoExt${i}`, form);
        }
      }
      form.set('tipoRequest', 'pedirEncuesta');
      this.props._surveyGetFetch(form);
    }
  }

  reloadSurvey = (reason, nonInteraction = false) => {
    Analytics.event(
      'reload',
      'reload-survey',
      reason,
      nonInteraction
    );
    this.setState({ showUnactiveModal: false }, () => window.location.reload());
  }

  checkLastActivity = () => {
    let {
      answers,
      requiredField,
      requiredFieldType,
      questions,
      currentQuestion,
    } = this.state;
    let { survey } = this.props;
    let selectedQuestion = questions[currentQuestion];
    let availableUnactivityTime = survey.data ? parseFloat(survey.data.MinutosReinicio) : 1;
    if (availableUnactivityTime) {
      setTimeout(() => {
        try {
          let diff = new Date().getTime() - this.state.lastActivity.getTime();
          let unactiveTime = parseFloat(((diff % 86400000) % 3600000) / 60000);
          if (answers.length >= 1 || (!requiredField && requiredFieldType)) {
            if (selectedQuestion.type === '99')
              this.reloadSurvey('end-survey', true);
            if (this.state.showUnactiveModal)
              this.reloadSurvey('timeout', true);
            else {
              if (unactiveTime < availableUnactivityTime)
                this.setState({ showUnactiveModal: false });
            }
          }
          this.checkLastActivity();
        } catch (e) { }
      }, 20000);
    }
  }

  setForm = () => {
    let clientInfo = deviceDetect();
    let form = new FormData();
    form.set('IdEmpresa', this.props.survey.data.IdEmpresa);
    form.set('IdEncuestaContacto', this.props.survey.data.IdEncuestaContacto);
    form.set('IdEncuestasIdiomas', this.props.survey.data.IdEncuestasIdiomas);
    form.set('IdEncuesta', this.props.survey.data.IdEncuesta);
    form.set('IdContacto', this.props.survey.data.IdContacto);
    form.set('IdiomaDefecto', this.props.survey.data.IdIdioma);
    form.set('RespuestaDatoContacto', this.state.requiredFieldValue);
    form.set('PideDatoContactoTipo', this.state.requiredFieldType);
    form.set('TipoCliente', clientInfo.isMobile ? 'mobile' : clientInfo.isTablet ? 'tablet' : 'desktop');
    form.set('ReferidoCliente', window.location.href);
    return form;
  }

  answerQuestion = (questionAnswer, nextIndexQuestion = null) => {
    let { questions, currentQuestion, answers, previousQuestion } = this.state;
    let selectedQuestion = questions[currentQuestion];

    if (this.props.answer.loading)
      return;

    Analytics.event(
      'answer',
      'answer-question-label',
      selectedQuestion.questionLabel
    );
    Analytics.event(
      'answer',
      'answer-question-id',
      selectedQuestion.questionId.toString(),
      parseInt(selectedQuestion.questionId)
    );

    let form = this.setForm();
    questionAnswer.time = moment().format('H:mm');
    let valueQuestionAnswer = Number(questionAnswer.value) ? Number(questionAnswer.value) : questionAnswer.value;
    form.set('Respuesta', valueQuestionAnswer);

    if (this.state.requiredField) {
      form.set('tipoRequest', 'PrimerDatoContacto');
      this.props._answerFetch(form);
    } else {
      selectedQuestion.answer = questionAnswer;
      if (answers.length >= 1)
        selectedQuestion.previousQuestion = previousQuestion;
      answers.push(selectedQuestion);

      if (nextIndexQuestion === null || nextIndexQuestion === undefined) {
        if (selectedQuestion.next.length === 1) {
          if (selectedQuestion.next.includes(questionAnswer))
            nextIndexQuestion = questionAnswer;
          else
            nextIndexQuestion = selectedQuestion.next[0];
        }
        if (questionAnswer.next)
          nextIndexQuestion = questionAnswer.next;
      }

      this.setState({
        answers,
        currentQuestion: nextIndexQuestion,
        previousQuestion: selectedQuestion.order,
        requiredField: false,
        showPopover: true,
        canPlay: this.state.canPlay && !['14', '99'].includes(selectedQuestion.type)
      });

      form.set('tipoRequest', 'GuardarRespuesta');
      form.set('IdPregunta', selectedQuestion.questionId);
      form.set('IdTipoPregunta', selectedQuestion.type);
      this.props._answerFetch(form);
    }
    this.state.questionSound.play();
  }

  _goBack = () => {
    let { answers } = this.state;
    let lastAnswer = answers.pop();
    this.setState({
      answers,
      currentQuestion: lastAnswer.order,
      previousQuestion: lastAnswer ? lastAnswer.previousQuestion : null,
      showPopover: false
    });
  }

  goBack = () => {
    let { answers } = this.state;
    if (answers.length > 0) {
      let lastAnswer = answers[answers.length - 1];
      let form = this.setForm();

      Analytics.event(
        'go-back',
        'go-back',
        lastAnswer.questionId.toString(),
        parseInt(lastAnswer.questionId)
      );
      form.set('IdPregunta', lastAnswer.questionId);
      form.set('tipoRequest', 'EliminarRespuesta');
      this.props._goBackFetch(form);
    } else
      this.reloadSurvey('go-back');
  }

  canRenderGoBack = () => {
    let { survey } = this.props;
    let { answers, questions, currentQuestion, requiredField, requiredFieldType } = this.state;
    let selectedQuestion = questions[currentQuestion];
    if (
      survey.data.PermiteVolverAtras === '1' &&
      survey.data.DatoGeneralVolverAtras &&
      (answers.length >= 1 || (!requiredField && requiredFieldType)) &&
      selectedQuestion && !['14', '99'].includes(selectedQuestion.type) &&
      this.state.canPlay
    )
      return true;
    return false;
  }

  renderGoback = () => {
    let { goBack, survey } = this.props;
    if (this.canRenderGoBack())
      return (
        <Button
          variant='link'
          className='go-back'
          label={<img src={survey.data.DatoGeneralVolverAtras} alt='go back' />}
          onClick={() => this.goBack()}
          disabled={goBack.loading}
        />
      )
  }

  canDisplayReloadModal = () => {
    let {
      answers,
      requiredField,
      requiredFieldType,
    } = this.state;
    let { survey } = this.props;
    let availableUnactivityTime = survey.data ? parseFloat(survey.data.MinutosReinicio) : 1;
    if (availableUnactivityTime) {
      let diff = new Date().getTime() - this.state.lastActivity.getTime();
      let unactiveTime = parseFloat(((diff % 86400000) % 3600000) / 60000);
      if (answers.length >= 1 || (!requiredField && requiredFieldType)) {
        if (unactiveTime >= availableUnactivityTime)
          this.setState({ showUnactiveModal: true });
      }
    }
  }

  canReset = () => {
    let { survey } = this.props;
    let { answers, requiredField, requiredFieldType } = this.state;
    let availableUnactivityTime = parseFloat(survey.data.MinutosReinicio);
    if (
      !this.state.showUnactiveModal &&
      availableUnactivityTime &&
      (answers.length >= 1 || (!requiredField && requiredFieldType))
    )
      return true;
    return false;
  }

  renderResetQuestion = () => {
    let availableUnactivityTime = parseFloat(this.props.survey.data.MinutosReinicio);
    let diff = new Date().getTime() - this.state.lastActivity.getTime();
    let unactiveTime = ((diff % 86400000) % 3600000) / 60000;
    if (this.canReset())
      return (
        <CircleTimeout
          availableTime={availableUnactivityTime - unactiveTime}
          initialTime={availableUnactivityTime}
          onClick={() => this.setState({ showUnactiveModal: true })}
          displayReload={() => this.canDisplayReloadModal()}
        />
      )
  }

  renderCompanyInfo = () => (
    <Card className='survey-card left'>
      <Card.Img variant="top" src={this.state.giftImage} />
      <div className='title'>
        <div dangerouslySetInnerHTML={{ __html: this.state.giftText }}></div>
      </div>
    </Card>
  )

  renderLastQuestions = () => {
    let { answers } = this.state;
    let { survey } = this.props;

    return (
      <div className='last-questions'>
        {answers.map(questionWithAnswer => {
          return (
            <div key={questionWithAnswer.order}>
              <div className='question'>
                <div className='label'>
                  <div dangerouslySetInnerHTML={{ __html: questionWithAnswer.questionLabel }}></div>
                </div>
                <div className='answer'>
                  <AnswerType
                    question={questionWithAnswer}
                    onAnswer={() => { }}
                    buttonLabel={null}
                  />
                </div>
              </div>
              {questionWithAnswer.answer.alt !== null &&
                questionWithAnswer.answer.alt !== undefined &&
                questionWithAnswer.type !== '9' ?
                <div className='question user'>
                  <div className='label'>
                    {questionWithAnswer.answer.time ? `(${questionWithAnswer.answer.time}) ` : null}
                    <strong>{survey.data.DatoGeneralYo}: </strong> {questionWithAnswer.answer.alt}
                  </div>
                </div> : null
              }
            </div>
          )
        })}
      </div>
    )
  }

  renderRequiredField = () => (
    <div className='required-field'>
      <div className='label'>
        {this.state.requiredFieldLabel}
      </div>
      <Input
        type={this.state.requiredFieldType}
        onChange={value => {
          let isValid = true;
          if (this.state.requiredFieldType === 'email')
            isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          this.setState({ requiredFieldValue: value, requiredFieldIsValid: isValid });
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.answerQuestion({
              value: this.state.requiredFieldIsValid, alt: this.state.requiredFieldIsValid
            });
          }
        }}
      />
      <Button
        label='Siguiente'
        disabled={!this.state.requiredFieldIsValid}
        onClick={() => this.answerQuestion({
          value: this.state.requiredFieldIsValid, alt: this.state.requiredFieldIsValid
        })}
      />
    </div>
  )

  renderCustomError = () => (
    <>
      {this.state.displayCustomError && (
        <Alert variant='danger'>
          {this.props.survey.data.DatoGeneralPerdioConexion}
        </Alert>
      )}
    </>
  )

  renderQuestion = () => {
    let { questions, currentQuestion, answers } = this.state;
    let { survey } = this.props;
    let selectedQuestion = questions[currentQuestion];
    let { answer } = this.props;
    let popover;

    if (selectedQuestion)
      popover = (
        <Popover id="help-popover" className={`help-popover ${answers.length ? 'more' : ''}`}>
          <Popover.Content>
            <div
              dangerouslySetInnerHTML={{ __html: selectedQuestion.help }}
              onClick={() => this.setState({ showPopover: false })}
            />
          </Popover.Content>
        </Popover>
      );

    return (
      <>
        {selectedQuestion && selectedQuestion.type !== '99' ?
          this.renderLastQuestions() : null
        }

        {selectedQuestion ?
          Object.keys(questions).length > 1 && selectedQuestion.type !== '99' ?
            <div className='question'>
              {selectedQuestion.help && this.state.showPopover ?
                <div className='help'>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover}
                    defaultShow={this.state.showPopover}
                  >
                    <div id={`popover-${selectedQuestion.order}`}></div>
                  </OverlayTrigger>
                </div> : null
              }
              {this.state.requiredField ? this.renderRequiredField() :
                <>
                  {answer.loading ?
                    <>
                      {this.renderLoading()}
                      {this.scrollToLastQuestion()}
                    </> :
                    <>
                      {this.scrollToLastQuestion()}
                      {selectedQuestion.type !== '99' ?
                        <div className='label'>
                          <div dangerouslySetInnerHTML={{ __html: selectedQuestion.questionLabel }}></div>
                        </div> : null
                      }
                      <div className='answer'>
                        <AnswerType
                          question={selectedQuestion}
                          onAnswer={(questionAnswer, nextQuestion = null) => this.answerQuestion(questionAnswer, nextQuestion)}
                          setForm={this.setForm}
                          withEmojis={survey.data.PermiteEmojis === '1'}
                          isRequired={survey.data.PermiteTextoOpcional !== '1'}
                        />
                        {this.renderGoback()}
                        {this.renderResetQuestion()}
                      </div>
                    </>
                  }
                </>
              }
            </div> :
            <AnswerType
              question={selectedQuestion}
              onAnswer={(questionAnswer, nextQuestion = null) => this.answerQuestion(questionAnswer, nextQuestion)}
              setForm={this.setForm}
              text={selectedQuestion.text}
            />
          : null}
      </>
    )
  }

  renderHealmet = () => (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{this.state.initialTitle}</title>
      {this.props.survey.data && this.props.survey.data.TipoEstilo ?
        <link rel="stylesheet" type="text/css" href={this.props.survey.data.TipoEstilo} /> : null
      }
    </Helmet>
  )

  renderLoading = () => (
    <div className='loading'>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      /> {this.props.survey.data ? this.props.survey.data.DatoGeneralCargandoPregunta : null}
    </div>
  )

  renderChatHeader = () => {
    let { questions, currentQuestion } = this.state;
    let { survey } = this.props;
    let answerReducer = this.props.answer;
    return (
      <div
        id='chatHeader'
        className={`chat-header ${survey.data.Barrita === '0' ? 'hide' : ''}`}
        style={{ backgroundColor: survey.data.BarritaColor }}
      >
        <Row>
          <Col xs='2' sm='2' className='header-logo'>
            <img src={this.state.chatLogo} alt={this.state.chatLogo} />
          </Col>
          <Col xs='8' sm='8' className='header-info'>
            <div className='company-name'>{this.state.chatName}</div>
            <div className='action'>{answerReducer.loading ? survey.data.DatoGeneralEscribiendo : 'online'}</div>
          </Col>
          <Col xs='2' sm='2' className='header-progress'>
            <div>
              {Object.keys(questions).length > 1 && currentQuestion ?
                `${(currentQuestion)}/${Object.keys(questions).length}` : null
              }
            </div>
            <div>
              {['BotonLegales', 'CompletoLegales'].includes(survey.data.LegalesTipo) ?
                <GoBriefcase onClick={() => this.setState({ showLegals: true })} /> : null
              }
            </div>
          </Col>
        </Row>
        {this.renderCustomError()}
      </div>
    )
  }

  renderPCSurvey = () => {
    let { questions, currentQuestion } = this.state;
    let selectedQuestion = questions[currentQuestion];

    if (Object.keys(questions).length === 0)
      return <></>

    return (
      <Row className='renders'>
        <Col className='company-info' xs='0' sm='12' md='6'>
          {this.renderCompanyInfo()}
          {this.renderCustomError()}
        </Col>
        <Col id='container' className='surveys' xs='12' sm='12' md='6'>
          <Card className='survey-card right' style={{ backgroundImage: `url(${this.state.phoneImage})` }}>
            <div className='chat' style={{ backgroundImage: `url(${this.state.chatImage})` }}>
              {this.renderChatHeader()}
              <div className='question-content'>
                {
                  Object.keys(questions).length > 1 &&
                    selectedQuestion.type !== '99' &&
                    this.state.initialText ?
                    <div className='question'>
                      <div className='label'>
                        {(this.state.imagenInicialMovil) ? <img className='img-inicial' src={this.state.imagenInicialMovil} /> : null}
                        <div dangerouslySetInnerHTML={{ __html: this.state.initialText }}></div>
                      </div>
                    </div> : null
                }
                <div id='scrollable-content'>
                  {this.renderQuestion()}
                </div>
                <div id='last-content' />
              </div>
            </div>
          </Card>
        </Col>
        <Col className='company-info last' xs='0' sm='12' md='6'>
          {this.renderCompanyInfo()}
        </Col>
      </Row>
    )
  }

  renderPCMobileSurvey = () => {
    let { questions, currentQuestion } = this.state;
    let selectedQuestion = questions[currentQuestion];

    if (Object.keys(questions).length === 0)
      return <></>

    return (
      <div
        id='container'
        className='chat full-background-img'
        style={{ backgroundImage: `url(${this.state.chatImage})` }}>
        {this.renderChatHeader()}
        <div className='question-content'>
          {Object.keys(questions).length > 1 && selectedQuestion && selectedQuestion.type !== '99' ?
            this.state.initialText ?
              <div className='question'>
                <div className='label'>
                  {(this.state.imagenInicialMovil) ? <img className='img-inicial' src={this.state.imagenInicialMovil} /> : null}
                  <div dangerouslySetInnerHTML={{ __html: this.state.initialText }}></div>
                </div>
              </div> : null
            : null
          }
          <div id='scrollable-content'>
            {this.renderQuestion()}
          </div>
          <div id='last-content' />
        </div>
      </div>
    )
  }

  renderResponsiveSurvey = () => {
    let { questions, currentQuestion } = this.state;
    let selectedQuestion = questions[currentQuestion];

    if (Object.keys(questions).length === 0)
      return <></>

    return (
      <Card id='container' className='responsive'>
        <div className='question-content'>
          {Object.keys(questions).length > 1 && selectedQuestion && selectedQuestion.type !== '99' ?
            this.state.initialText ?
              <div className='question'>
                <div className='label'>
                  {(this.state.imagenInicialPC) ? <img className='img-inicial' src={this.state.imagenInicialPC} /> : null}
                  <div dangerouslySetInnerHTML={{ __html: this.state.initialText }}></div>
                </div>
              </div> : null
            : null
          }
          {this.renderCustomError()}
          <div id='scrollable-content' className='responsive-content'>
            {this.renderQuestion()}
          </div>
          <div id='last-content' />
        </div>
      </Card>
    )
  }

  renderKioscoSurvey = () => {
    let { survey } = this.props;
    let { questions, currentQuestion, answers } = this.state;
    let selectedQuestion = questions[currentQuestion];
    if (Object.keys(questions).length === 0)
      return <></>

    return (
      <div className='kiosco'>
        {selectedQuestion && selectedQuestion && selectedQuestion.type !== '99' && this.state.initialImage ?
          <img variant="top" src={this.state.initialImage} alt={this.state.companyName} /> : null
        }
        <div className='question-content'>
          {
            Object.keys(questions).length > 1 && this.state.initialText &&
              selectedQuestion.type !== '99' &&
              this.state.initialText &&
              (survey.data.TipoPresenta !== 'Kiosco' || (answers.length === 0 && survey.data.TipoPresenta === 'Kiosco')) ?
              <div className='question'>
                <div className='label'>
                  <div dangerouslySetInnerHTML={{ __html: this.state.initialText }}></div>
                </div>
              </div> : null
          }
          <div id='scrollable-content' className='responsive-content'>
            {this.renderQuestion()}
          </div>
        </div>
      </div>
    )
  }

  renderType = () => {
    let { survey } = this.props;
    if (!survey.data)
      return;

    if (survey.data.TipoPresenta === 'Responsive')
      return isMobileOnly ? this.renderPCMobileSurvey() : this.renderResponsiveSurvey();
    else if (survey.data.TipoPresenta === 'PcDoble')
      return isMobile ? this.renderPCMobileSurvey() : this.renderPCSurvey();
    else
      return this.renderKioscoSurvey();
  }

  handleClose = () => this.setState({
    showUnactiveModal: false,
    showLegals: false || this.state.legalsRequired,
    lastActivity: new Date()
  });

  rejectLegals = () => {
    let { survey } = this.props;

    Analytics.event(
      'legals',
      'reject-legals',
      survey.data.IdEncuesta.toString(),
      parseInt(survey.data.IdEncuesta)
    );

    let form = this.setForm();
    form.set('Respuesta', 0);
    form.set('tipoRequest', 'ActualizaLegales');
    this.props._legalsFetch(form);

    window.location.replace(survey.data.LegalesLinkDeclinado);
  }

  acceptLegals = () => {
    let { survey } = this.props;
    this.setState({ legalsRequired: false }, this.handleClose);

    Analytics.event(
      'legals',
      'accept-legals',
      survey.data.IdEncuesta.toString(),
      parseInt(survey.data.IdEncuesta)
    );

    let form = this.setForm();
    form.set('Respuesta', 1);
    form.set('tipoRequest', 'ActualizaLegales');
    this.props._legalsFetch(form);
  }

  render() {
    let { survey } = this.props;
    return (
      <>
        {this.renderHealmet()}

        {survey.data && this.state.showLegals ?
          <Legals
            show={this.state.showLegals ? true : false}
            onHide={() => this.handleClose()}
            legalText={survey.data.LegalesTexto}
            buttons={survey.data.LegalesBotones.split('|')}
            rejectAction={this.rejectLegals}
            acceptAction={this.acceptLegals}
          /> : null
        }

        {survey.data ?
          <Modal show={this.state.showUnactiveModal} onHide={() => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>{survey.data.DatoGeneralReinicioTexto && survey.data.DatoGeneralReinicioTexto.split('|')[0]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{survey.data.DatoGeneralReinicioTexto && survey.data.DatoGeneralReinicioTexto.split('|')[1]}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.reloadSurvey('manual-reload')}
                label={survey.data.DatoGeneralReinicioBotonReiniciar}
              />
              <Button
                onClick={() => this.handleClose()}
                label={survey.data.DatoGeneralReinicioBotonSeguir}
              />
            </Modal.Footer>
          </Modal> : null
        }

        <BrowserView>
          <div className='survey-container'>
            <Row>
              <Col xs='0' sm='1'></Col>
              <Col xs='12' sm='10'>
                {survey.loading ? this.renderLoading() : this.renderType()}
              </Col>
              <Col xs='0' sm='1'></Col>
            </Row>
          </div>
        </BrowserView>
        <MobileView>
          {isMobileOnly ?
            <div className='survey-container'>
              {survey.loading ? this.renderLoading() : this.renderType()}
            </div> :
            <Container className='survey-container'>
              {survey.loading ? this.renderLoading() : this.renderType()}
            </Container>
          }
        </MobileView>
      </>
    );
  }
}

export default Survey;
