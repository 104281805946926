import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';

class JackPot extends React.Component {

  componentDidMount() {
    window.addEventListener('message', this.messageFromIframe);
  }

  messageFromIframe = e => {
    console.log('messageFromIframe', e);
    if (e.data && e.data.data === 'jack-pot')
      setTimeout(() => {
        let prize = e.data.value;
        this.props.onAnswer(
          { value: prize, alt: this.props.labels[parseInt(prize)] },
          this.props.nextQuestions[parseInt(prize)]
        );
      }, 5000);
  };

  handleLoad = () => {
    let iframe = document.getElementById('jackpot');
    if (isMobileOnly) {
      let data = [
        [
          '#container',
          {
            left: 0,
            right: 0,
            width: 'auto',
            height: 'auto',
            'margin-left': 0
          }
        ],
        [
          '#container #header',
          {
            width: '100%',
            height: 'auto',
            'background-size': '100% auto',
          }
        ],
        [
          '#container #header h1',
          {
            font: '28px Francois One',
            'padding-top': '31px',
          }
        ],
        [
          '#reels',
          {
            display: 'flex',
          }
        ],
        [
          '#container #reels canvas',
          {
            width: '33%',
            height: '800px !important'
          }
        ],
        [
          '#container #buttons #play',
          {
            bottom: '-70px'
          }
        ],
        [
          '#reels #results',
          {
            left: '15%',
            right: '15%',
            width: '70%',
          }
        ],
      ]
      iframe.contentWindow.postMessage(data, '*');
    }
  }

  render() {
    let jackpotUrl = `${process.env.REACT_APP_BASE_URL}ruleta/index.php`;
    let languageId = this.props.survey.data.IdIdioma;
    let surveyLanguageId = this.props.survey.data.IdEncuestasIdiomas;
    return (
      <iframe
        src={`${jackpotUrl}?IdEncuestasIdiomas=${surveyLanguageId}&IdIdioma=${languageId}`}
        id='ruleta'
        className='jackpot'
        style={{ display: 'inline-block', position: 'relative', width: '100%', height: '550px', border: 'none' }}
        title='ruleta'
        onLoad={() => this.handleLoad()}
        />
    )
  }
}

JackPot.propTypes = {
  onAnswer: PropTypes.func.isRequired,
  labels: PropTypes.string.isRequired,
};

export default JackPot;