import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './button.css';

const MyButton = props => (
  <Button 
    onClick={() => props.onClick(props.value)}
    className={`custom-button ${props.className}`}
    disabled={props.disabled}
    variant={props.variant}
  >
    {props.label}
  </Button>
)

MyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

MyButton.defaultProps = {
  disabled: false,
  variant: 'primary'
};

export default MyButton;