export const ACTIONS = {
  SURVEY: {
    GET: {
      FETCH: "SURVEY_GET_FETCH",
      SUCCESS: "SURVEY_GET_SUCCESS",
      FAIL: "SURVEY_GET_FAIL"
    },
    USER_IP: {
      FETCH: "SURVEY_USER_IP_FETCH",
      SUCCESS: "SURVEY_USER_IP_SUCCESS",
      FAIL: "SURVEY_USER_IP_FAIL"
    },
    UPLOAD_FILE: {
      FETCH: "SURVEY_UPLOAD_FILE_FETCH",
      SUCCESS: "SURVEY_UPLOAD_FILE_SUCCESS",
      FAIL: "SURVEY_UPLOAD_FILE_FAIL"
    },
    ANSWER: {
      FETCH: "SURVEY_ANSWER_FETCH",
      SUCCESS: "SURVEY_ANSWER_SUCCESS",
      FAIL: "SURVEY_ANSWER_FAIL"
    },
    GO_BACK: {
      FETCH: "SURVEY_GO_BACK_FETCH",
      SUCCESS: "SURVEY_GO_BACK_SUCCESS",
      FAIL: "SURVEY_GO_BACK_FAIL"
    },
    END: {
      FETCH: "SURVEY_END_FETCH",
      SUCCESS: "SURVEY_END_SUCCESS",
      FAIL: "SURVEY_END_FAIL"
    },
    SOCIAL_COUNT: {
      FETCH: "SURVEY_SOCIAL_COUNT_FETCH",
      SUCCESS: "SURVEY_SOCIAL_COUNT_SUCCESS",
      FAIL: "SURVEY_SOCIAL_COUNT_FAIL"
    },
    LEGALS: {
      FETCH: "LEGALS_FETCH"
    }
  },
}