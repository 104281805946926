import React from 'react';
import PropTypes from 'prop-types';
import { ReactMic } from 'react-mic';
import { AiFillAudio } from 'react-icons/ai';
import { FaStopCircle } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import { Alert } from 'react-bootstrap';

import { emailRegex } from '../../constants';

import Button from '../../../components/Button/button';
import Input from '../../../components/Input/input';

class AnswerText extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      record: false,
      sizeError: false,
      uploadError: false,
      isValidEmail: false
    };
  }

  componentDidUpdate(prevProps) {
    let oldFile = prevProps.file;
    let { file } = this.props;
    if (oldFile.loading && !file.loading) {
      if (!file.error && file.data)
        this.props.onClick({ value: file.data });
      else
        this.setState(
          { uploadError: true },
          () => setTimeout(() => this.setState({ uploadError: false }), 5000)
        );
    }
  }

  startRecording = () => this.setState({ record: true });

  stopRecording = () => this.setState({ record: false });

  onData = recordedBlob => { }

  onStop = recordedBlob => this.setState({ file: recordedBlob, blobURL: recordedBlob.blobURL });

  uploadFileToS3 = () => {
    let formData = new FormData();
    let fileName = (Math.random() * 1000).toString().replace('.', '');
    formData.append('audio-filename', `${fileName}.mp3`);
    formData.append('audio-blob', this.state.file.blob);
    this.props._uploadFetch(formData);
  }

  canAnswerQuestion = () => {
    if (this.props.isRequired) {
      if (this.props.type === 'email')
        return this.state.isValidEmail;
      else
        return this.state.answer;
    } else
      return true;
  }

  answerQuestion = () => {
    if (this.canAnswerQuestion())
      this.props.onClick({ value: this.state.answer, alt: this.state.answer });
  }

  onInputChange = value => {
    this.setState({ answer: value });
    if (this.props.type === 'email') {
      if (value && emailRegex.test(value))
        this.setState({ isValidEmail: true });
      else
        this.setState({ isValidEmail: false });
    }
  }

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={this.answerQuestion}
          className={this.props.buttonClassName}
          label={this.props.buttonLabel}
          disabled={!this.canAnswerQuestion()}
        /> : null
      }
    </>
  )

  render() {
    return (
      <div className={`answer-text ${this.props.className}`}>
        <Input
          onChange={this.onInputChange}
          className={this.props.inputClassName}
          multiLine={this.props.multiLine}
          type={this.props.type}
          isAudio={this.props.isAudio}
          value={this.state.answer ? this.state.answer : this.props.defaultValue.value}
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              this.answerQuestion();
            }
          }}
          min={this.props.type === 'number' ? '0' : null}
          step={this.props.type === 'number' ? '0' : null}
          withEmojis={this.props.withEmojis}
        />
        {this.props.isAudio ?
          <div className='audio-recorder'>
            <ReactMic
              record={this.state.record}
              className="sound-wave"
              onStop={this.onStop}
              onData={this.onData}
              strokeColor="#000000"
              backgroundColor="rgba(255,255,255,0.74)"
            />
            <Button
              onClick={() => this.stopRecording()}
              label={<FaStopCircle />}
              variant='link'
            />
            <Button
              onClick={() => this.startRecording()}
              label={<AiFillAudio />}
              variant='link'
            />
            {this.state.blobURL ?
              <Button
                onClick={() => this.uploadFileToS3()}
                label={<MdFileUpload />}
                variant='link'
              /> : null
            }
            <div className='audio-control'>
              {this.state.blobURL ?
                <>
                  <audio ref="audioSource" controls="controls" src={this.state.blobURL} />
                </> : null
              }
            </div>
          </div> : null
        }
        {this.state.uploadError ?
          <Alert variant='danger'>
            Ocurrió un error subiendo el archivo
          </Alert>
          : null}
        
        {this.renderButton()}
      </div>
    )
  }
}

AnswerText.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.any.isRequired,
  maxLength: PropTypes.number,
  multiLine: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  type: PropTypes.string,
  isAudio: PropTypes.bool,
  defaultValue: PropTypes.object,
  withEmojis: PropTypes.bool,
  isRequired: PropTypes.bool,
};

AnswerText.defaultProps = {
  multiLine: false,
  maxLength: 250,
  type: 'text',
  isAudio: false,
  defaultValue: {},
  withEmojis: false,
  isRequired: true
};

export default AnswerText;