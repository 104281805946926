import React from 'react';
import PropTypes from 'prop-types';
import { FaCamera } from 'react-icons/fa';
import { Alert, Spinner } from 'react-bootstrap';

import Button from '../../../components/Button/button';
import Camera from '../../../components/Camera/camera';

class AnswerFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      previewImage: null,
      showPhotoPicker: this.props.cameraOnly,
      fileName: null,
      sizeError: false,
      uploadError: false
    };
  }

  componentDidUpdate(prevProps) {
    let oldFile = prevProps.file;
    let { file } = this.props;
    if (oldFile.loading && !file.loading) {
      if (!file.error && file.data)
        this.answerQuestion(file.data, file.data);
      else
        this.setState(
          { uploadError: true },
          () => setTimeout(() => this.setState({ uploadError: false }), 5000)
        );
    }
  }

  buildImage = file => {
    let componentThis = this;
    let _URL = window.URL || window.webkitURL;

    let img = new Image();
    img.onload = e => componentThis.setState({
      file,
      previewVideo: null,
      previewImage: img.src,
      fileName: 'image'
    });
    img.src = _URL.createObjectURL(file);
  }

  buildVideo = file => {
    let componentThis = this;
    let reader = new FileReader();

    // if reading completed
    reader.onload = e => {
      componentThis.setState({
        previewVideo: e.target.result,
        file: new Blob([e.target.result], { type: file.type }),
        fileName: 'video',
        sizeError: false,
        previewImage: null
      });
    };

    // read the file
    reader.readAsDataURL(file);
  }

  handleFileChange = e => {
    if (!e.target.files || e.target.files.length === 0)
      return;
    let file = e.target.files[0];
    if (file.type.indexOf('image') > -1)
      this.buildImage(file);
    else if (file.type.indexOf('video') > -1)
      this.buildVideo(file);
  }

  onTakePhoto = screenshot => {
    this.setState({ screenshot, showPhotoPicker: false });
    let i = new Image();
    i.src = screenshot;

    let block = screenshot.split(";");
    let contentType = block[0].split(":")[1];
    this.setState({ fileExtension: contentType.split('/')[1] });
    let realData = block[1].split(",")[1];
    let sliceSize = 512;

    let byteCharacters = atob(realData);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++)
        byteNumbers[i] = slice.charCodeAt(i);

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    this.buildImage(blob);
  }

  uploadFileToS3 = () => {
    let formData = new FormData();
    let fileExtension = this.state.file.type.split('/')[1];
    let randomName = (Math.random() * 1000).toString().replace('.', '');
    formData.append(`${this.state.fileName}-filename`, `${randomName}.${fileExtension}`);
    formData.append(`${this.state.fileName}-blob`, this.state.file);
    this.props._uploadFetch(formData);
  }

  answerQuestion = (value, link) => this.props.onClick({ value, link });

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={() => this.uploadFileToS3()}
          className={this.props.buttonClassName}
          label={!this.props.file.loading ?
            this.props.buttonLabel :
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {this.props.errorMessage ? this.props.errorMessage[6] : null}
            </>
          }
          disabled={!this.state.file || this.props.file.loading}
        /> : null
      }
    </>
  )

  render() {
    return (
      <div className={`file-answer ${this.props.className}`}>
        <div className='select-file'>
          {!this.props.cameraOnly ?
            <>
              <div>
                <input
                  type='file'
                  name='file'
                  accept='image/*, video/*'
                  onChange={e => this.handleFileChange(e)}
                />
              </div>
              <div className='o'>o</div>
            </> : null
          }
          <div className='camera'>
            {this.state.showPhotoPicker ?
              <Camera
                file={this.state.file}
                onTakePhoto={this.onTakePhoto}
                idealFacingMode={this.props.cameraOnly}
                onCameraError={e => this.answerQuestion(e, '#')}
              /> :
              <div className='pick' onClick={() => this.setState({ showPhotoPicker: true })}>
                {this.props.errorMessage ? this.props.errorMessage[4] : null} <FaCamera />
              </div>
            }
          </div>
          <div className='errors' style={{ display: 'flex' }}>
            {this.state.sizeError ?
              <Alert variant='danger'>
                {this.props.errorMessage ? this.props.errorMessage[2] : null}
              </Alert>
              : null}
            {this.state.uploadError ?
              <Alert variant='danger'>
                {this.props.errorMessage ? this.props.errorMessage[3] : null}
              </Alert>
              : null}
            {this.props.answer.error ?
              <Alert variant='danger'>
                {this.props.errorMessage ? this.props.errorMessage[1] : null}
              </Alert>
              : null}
          </div>
        </div>

        <div className='preview'>
          {!this.state.showPhotoPicker && this.state.previewImage ? <img alt='preview' src={this.state.previewImage} /> : null}
          {!this.state.showPhotoPicker && this.state.previewVideo ?
            <video controls>
              <source
                src={this.state.previewVideo}
                type={this.state.file.type}
              />
              Your browser does not support HTML5 video.
            </video> : null
          }
        </div>

        {this.renderButton()}
      </div>
    )
  }
}

AnswerFile.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.any.isRequired,
  className: PropTypes.string,
  cameraOnly: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array,
};

AnswerFile.defaultProps = {
  cameraOnly: false
};

export default AnswerFile;