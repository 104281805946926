import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Store, Persistor } from './store';

import {App} from './App';

const Root = () => (
  <Provider store={Store}>
    <PersistGate loading={null} persistor={Persistor}>
      <App />
    </PersistGate>
  </Provider>
);

export default Root;