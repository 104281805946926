import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import './radio.css';

const Radio = props => (
  <Form.Check
    type='radio'
    id={props.id}
    onChange={e => props.onChange(props.value)}
    name={props.name}
    className={props.className}
    label={props.label}
    checked={props.defaultChecked}
  />
)

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.number,
};

export default Radio;