import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

import Root from './Root';

import './index.css';

Sentry.init({ dsn: `https://${process.env.REACT_APP_SENTRY_KEY}@${process.env.REACT_APP_SENTRY_ORGANIZATION}.ingest.sentry.io/${process.env.REACT_APP_SENTRY_ID}` });

render(
  <Root />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
