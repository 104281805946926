import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Analytics from './services/Analytics';
import { Survey } from './views/surveys';

import './App.css';
import './assets/styles/main.css';

class AppComponent extends React.Component {

  constructor(props) {
    super(props);
    Analytics.initializeReactGA();
  }

  render() {
    return (
      <Survey />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);