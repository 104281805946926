import React from 'react';
import { PropTypes } from 'prop-types';
import { Card } from 'react-bootstrap';
const retardo = 1000;

class EndSurvey extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let form = this.props.setForm();
    console.log('final de encuesta survey')
    form.set('tipoRequest', 'FinalEncuesta');
    setTimeout(() => {
      this.props._endFetch(form);
    }, retardo)
  }

  updateSocialCount = socialName => {
    let form = this.props.setForm();
    form.set('tipoRequest', 'ActualizaShare');
    form.set('RedSocial', socialName);
    setTimeout(() => {
      this.props._socialCountFetch(form);
    }, retardo)
  }

  render() {
    let { survey, end } = this.props;
    let text;
    if (survey.data.FinalTextoEncuestaFinalizada)
      text = survey.data.FinalTextoEncuestaFinalizada;
    else if (survey.data.FinalTextoVencido)
      text = survey.data.FinalTextoVencido;

    if (end.data && !text)
      text = end.data[Object.keys(end.data)[0]];

    return (
      <div className='end-survey'>
        {end.data ?
          <Card>
            <Card.Img variant="top" src={survey.data.ImagenLogo} />
            <Card.Body>
              <div dangerouslySetInnerHTML={{ __html: text }} />

              <div className='share-buttons'>
                {end.data.Share && Object.keys(end.data.Share).map(shareIndex => {
                  let shareOptions = end.data.Share[shareIndex];
                  return (
                    <a
                      href={shareOptions.href}
                      target='_blank'
                      onClick={() => this.updateSocialCount(shareOptions.value)}
                      rel='noopener noreferrer'
                    >
                      <img
                        alt={shareOptions.alt}
                        title={shareOptions.alt}
                        value={shareOptions.value}
                        src={shareOptions.image}
                      />
                    </a>
                  )
                })}
              </div>

              {survey.data.DatoGeneralPoweredBy ?
                <div className='powered'>
                  <p className='muted'>{survey.data.DatoGeneralPoweredBy}</p>
                  <a
                    href={survey.data.DatoGeneralPoweredByLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={survey.data.DatoGeneralPoweredByImagen} alt={survey.data.DatoGeneralPoweredBy} />
                  </a>
                </div> : null
              }
            </Card.Body>
          </Card> : null
        }
      </div>
    )
  }
}

EndSurvey.propTypes = {
  setForm: PropTypes.func.isRequired,
};

export default EndSurvey;