import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Button from '../../../components/Button/button';

class Legals extends React.Component {

  render() {
    return (
      <Modal
        size="lg"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{__html: this.props.legalText}}></div>
          {this.props.buttons.length === 2 ?
            <div className='legals-buttons'>
              <Button
                onClick={this.props.rejectAction}
                label={this.props.buttons[1]}
                variant='danger'
              />
              <Button
                onClick={this.props.acceptAction}
                label={this.props.buttons[0]}
              />
            </div> : null
          }
        </Modal.Body>
      </Modal>
    )
  }
}

Legals.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  legalText: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  rejectAction: PropTypes.func,
  acceptAction: PropTypes.func
};

export default Legals;