import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { IoIosArrowDropright, IoIosArrowDropleft } from 'react-icons/io';

import Button from '../../../components/Button/button';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

class AnswersCarousel extends React.Component {

  renderButton = item => (
    <>
      {this.props.buttonLabel !== null ? 
        <Button
          label={this.props.buttonLabel}
          onClick={() => this.props.onClick({ alt: item.name, value: item.id }, item.next)}
        /> : null
      }
    </>
  )

  render() {
    return (
      <div className='carousel'>
        <AliceCarousel
          responsive={{
            0: { items: 1 },
            600: { items: 2 },
            800: { items: 3 },
            992: { items: 4 },
          }}
          autoPlayInterval={4000}
          autoPlayDirection="ltr"
          autoPlay={this.props.items.length > 1}
          buttonsDisabled={true}
          dotsDisabled={true}
          mouseTrackingEnabled={true}
          touchTrackingEnabled={true}
          items={
            this.props.items.map(item => (
              <Card key={item.id.toString()}>
                <Card.Img variant="top" src={item.image} alt={item.name} />
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  {this.renderButton(item)}
                </Card.Body>
              </Card>
            ))
          }
          ref={el => this.Carousel = el}
        />
        <div className='carousel-controller'>
          <Button
            onClick={() => this.Carousel.slidePrev()}
            label={<IoIosArrowDropleft />}
            className='previous'
            variant='link'
          />
          <Button
            onClick={() => this.Carousel.slideNext()}
            label={<IoIosArrowDropright />}
            className='next'
            variant='link'
          />
        </div>
      </div>
    )
  }
}

AnswersCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired
};

export default AnswersCarousel;