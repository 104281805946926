import {ACTIONS} from "../ActionTypes";

// GET SURVEYS
export function surveyGetFetch(data) {
  return {type: ACTIONS.SURVEY.GET.FETCH, payload: {data}}
}

export function surveyGetSuccess(data) {
  return {type: ACTIONS.SURVEY.GET.SUCCESS, payload: {data}}
}

export function surveyGetFail(error) {
  return {type: ACTIONS.SURVEY.GET.FAIL, payload: error}
}

// GET USER'S IP
export function userIdFetch() {
  return {type: ACTIONS.SURVEY.USER_IP.FETCH}
}

export function userIdSuccess(data) {
  return {type: ACTIONS.SURVEY.USER_IP.SUCCESS, payload: {data}}
}

export function userIdFail(error) {
  return {type: ACTIONS.SURVEY.USER_IP.FAIL, payload: error}
}

// Upload file to s3
export function uploadFetch(data) {
  return {type: ACTIONS.SURVEY.UPLOAD_FILE.FETCH, payload: {data}}
}

export function uploadSuccess(data) {
  return {type: ACTIONS.SURVEY.UPLOAD_FILE.SUCCESS, payload: {data}}
}

export function uploadFail(error) {
  return {type: ACTIONS.SURVEY.UPLOAD_FILE.FAIL, payload: error}
}

// Answer a question
export function answerFetch(data) {
  return {type: ACTIONS.SURVEY.ANSWER.FETCH, payload: {data}}
}

export function answerSuccess(data) {
  return {type: ACTIONS.SURVEY.ANSWER.SUCCESS, payload: {data}}
}

export function answerFail(error) {
  return {type: ACTIONS.SURVEY.ANSWER.FAIL, payload: error}
}

// Go back
export function goBackFetch(data) {
  return {type: ACTIONS.SURVEY.GO_BACK.FETCH, payload: {data}}
}

export function goBackSuccess() {
  return {type: ACTIONS.SURVEY.GO_BACK.SUCCESS}
}

export function goBackFail(error) {
  return {type: ACTIONS.SURVEY.GO_BACK.FAIL, payload: error}
}

// End
export function endFetch(data) {
  return {type: ACTIONS.SURVEY.END.FETCH, payload: {data}}
}

export function endSuccess(data) {
  return {type: ACTIONS.SURVEY.END.SUCCESS, payload: {data}}
}

export function endFail(error) {
  return {type: ACTIONS.SURVEY.END.FAIL, payload: error}
}

// Social's counter
export function socialCountFetch(data) {
  return {type: ACTIONS.SURVEY.SOCIAL_COUNT.FETCH, payload: {data}}
}

export function socialCountSuccess() {
  return {type: ACTIONS.SURVEY.SOCIAL_COUNT.SUCCESS}
}

export function socialCountFail(error) {
  return {type: ACTIONS.SURVEY.SOCIAL_COUNT.FAIL, payload: error}
}

// Legals
export function legalsFetch(data) {
  return {type: ACTIONS.SURVEY.LEGALS.FETCH, payload: {data}}
}