import React from 'react';
import PropTypes from 'prop-types';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import * as Sentry from '@sentry/browser';

const MyCamera = props => (
  <>
    <Camera
      onTakePhoto={dataUri => props.onTakePhoto(dataUri)}
      onCameraError={error => {
        Sentry.captureException(error);
        if (props.onCameraError)
          props.onCameraError(error);
      }}
      idealResolution={{ width: 450, height: 450 }}
      idealFacingMode={
        props.idealFacingMode === 'environment' ?
          FACING_MODES.ENVIRONMENT :
          FACING_MODES.USER
      }
      imageType={IMAGE_TYPES.PNG}
      isMaxResolution={false}
      isImageMirror={false}
      isSilentMode={true}
      imageCompression={0.95}
    />
  </>
)

MyCamera.propTypes = {
  onTakePhoto: PropTypes.func,
  idealFacingMode: PropTypes.string,
  onCameraError: PropTypes.func
};

MyCamera.defaultProps = {
  idealFacingMode: 'environment'
};

export default MyCamera;