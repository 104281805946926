import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button/button';

const Marker = props => {

  const markerStyle = {
    height: 15,
    width: 15,
    cursor: 'pointer',
    zIndex: 10,
    marginTop: -35,
    top: "50%",
    left: "50%",
    transform: `translate("-50%", "-50%")`
  };

  return (
    <img src={props.markerIcon} alt={props.markerName} style={markerStyle} />
  );
};

Marker.propTypes = {
  markerIcon: PropTypes.string.isRequired,
  markerName: PropTypes.string.isRequired
};

class SearchBox extends Component {
  static propTypes = {
    mapsapi: PropTypes.shape({
      places: PropTypes.shape({
        SearchBox: PropTypes.func,
      }),
      event: PropTypes.shape({
        clearInstanceListeners: PropTypes.func,
      }),
    }).isRequired,
    placeholder: PropTypes.string,
    onPlacesChanged: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placeholder: 'Search...',
  };

  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    const {
      mapsapi: { places },
    } = this.props;

    this.searchBox = new places.SearchBox(this.searchInput.current);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }

  componentWillUnmount() {
    const { mapsapi: { event } } = this.props;
    event.clearInstanceListeners(this.searchBox);
  }

  onPlacesChanged = () => {
    let place = this.searchBox.getPlaces();
    let lat = place[0].geometry.location.lat();
    let lng = place[0].geometry.location.lng();
    let formattedAddress = place[0].formatted_address;
    let icon = place[0].icon;
    let name = place[0].name;
    this.props.onPlacesChanged({ lat, lng }, formattedAddress, icon, name);
  };

  render() {
    const { placeholder } = this.props;

    return (
      <input
        ref={this.searchInput}
        placeholder={placeholder}
        type="text"
      />
    );
  }
}

class GoogleMaps extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mapsApiLoaded: false,
      mapInstance: null,
      mapsapi: null,
      center: { lat: 59.95, lng: 30.33 },
      marketIcon: null,
      address: null,
      name: null
    };
    if (this.props.defaultValue.value)
      this.state.center = {
        lat: parseFloat(this.props.defaultValue.value.split('|')[0]),
        lng: parseFloat(this.props.defaultValue.value.split('|')[1])
      }
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      location => {
        let lat = location.coords.latitude;
        let lng = location.coords.longitude;
        this.setState({ center: { lat, lng } });
      },
      error => { },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 3000 }
    );
  }

  apiLoaded = (map, maps) => {
    this.setState({
      mapsApiLoaded: true,
      mapInstance: map,
      mapsapi: maps,
    });
  }

  renderButton = () => {
    let { name } = this.state;
    return (
      <>
        {this.props.buttonLabel !== null ?
          <Button
            onClick={() => {
              let { lat, lng } = this.state.center;
              let { address } = this.state;
              let value = `${lat}|${lng}|${address}`;
              this.props.onClick({ value, alt: address });
            }}
            label={this.props.buttonLabel}
            disabled={!name}
          /> : null
        }
      </>
    )
  }

  render() {
    let { mapInstance, mapsapi, mapsApiLoaded, name, marketIcon, center } = this.state;
    return (
      <div className='map-container'>
        <div className='map'>
          {mapsApiLoaded ?
            <SearchBox
              map={mapInstance}
              mapsapi={mapsapi}
              onPlacesChanged={
                (center, address, marketIcon, name) => this.setState({ center, address, marketIcon, name })
              }
            /> : null
          }
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAPS_KEY,
              libraries: ['places', 'drawing']
            }}
            center={center}
            defaultZoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              this.apiLoaded(map, maps);
            }}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              panControl: false,
              scrollwheel: false,
            }}
          >
            {name ?
              <Marker
                markerIcon={marketIcon}
                markerName={name}
                lat={center.lat}
                lng={center.lng}
              /> : null
            }
          </GoogleMapReact>
        </div>
        
        {this.renderButton()}
      </div>
    );
  }
}

export default GoogleMaps;
