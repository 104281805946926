import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import Button from '../../../components/Button/button';
import Input from '../../../components/Input/input';

class AnswerSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.defaultValue.oldAnswers ?
        Object.assign([], this.props.defaultValue.oldAnswers) :
        Object.assign([], this.props.answers),
      selectedAnswer: null,
      displayText: false,
      keyWithText: null,
      othersText: '',
      selectLabel: 'Seleccionar'
    };

    this.state.answers.forEach(answer => {
      if (answer.label && answer.label.slice(-1) === '*')
        this.state.keyWithText = answer.key;
      if (answer.selected)
        this.state.selectLabel = answer.label;
    });
  }

  buttonDisabled = () => {
    return !this.state.selectedAnswer || (
      this.state.selectedAnswer && this.state.displayText && !this.state.othersText);
  }

  renderButton = () => (
    <>
      {this.props.buttonLabel !== null ?
        <Button
          onClick={() => {
            let { selectedAnswer, displayText, othersText, answers } = this.state;
            let value = selectedAnswer.key;
            let alt = selectedAnswer.label;
            if (displayText) {
              value = `${value}|${this.state.othersText}`;
              alt = `${alt}${othersText ? ': ' + othersText : ''}`;
            }
            this.props.onClick({ value, alt, oldAnswers: answers }, parseInt(selectedAnswer.next));
          }}
          className={this.props.buttonClassName}
          label={this.props.buttonLabel}
          disabled={this.buttonDisabled()}
        /> : null
      }
    </>
  )

  render() {
    return (
      <div className={`answer-select ${this.props.className}`}>
        <Dropdown>
          <Dropdown.Toggle variant="link" id={`dropdown-question-${this.props.questionId}`}>
            {this.state.selectLabel}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {this.state.answers.map((answer, index) => {
              return (
                <Dropdown.Item
                  href="#"
                  key={answer.key.toString()}
                  value={answer.value}
                  onClick={e => {
                    this.state.answers.forEach(answer => answer.selected = false);
                    answer.selected = true;
                    let answers = this.state.answers;
                    answers[index] = answer;
                    this.setState({
                      answers,
                      selectedAnswer: answer,
                      displayText: answer.key === this.state.keyWithText,
                      selectLabel: answer.label
                    });
                  }}
                >
                  {answer.label}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>

        {this.state.displayText ?
          <Input
            onChange={label => this.setState({ othersText: label })}
            value={this.state.othersText}
          /> : null
        }
        
        {this.renderButton()}
      </div>
    )
  }
}

AnswerSelect.propTypes = {
  answers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  defaultValue: PropTypes.object
};

AnswerSelect.defaultProps = {
  defaultValue: {},
};

export default AnswerSelect;